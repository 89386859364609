import { comandaEtapasReuniaoAtom } from './ComandaEtapaReuniaoState';
import { useBaseActions } from '../../../base.actions';

function useComandaEtapaReuniaoActions() {
  const baseActions = useBaseActions('comandaEtapasReuniao', comandaEtapasReuniaoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useComandaEtapaReuniaoActions };
