import { useBaseActions } from '../../../base.actions';
import { enderecoAtom } from './EnderecoState';

function useEnderecoActions() {
  const baseActions = useBaseActions('enderecos', enderecoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useEnderecoActions };
