import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Grid,
  Checkbox,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { IArea, IGrupo } from '../../../../../commons/genericTypes';
import { TextFieldStato, SelectStato } from '../../../../../commons/styleds';
import { ModeloComercial, NivelHierarquico, TipoConsultor } from '../../../../../commons/enums';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { NumericFormat } from 'react-number-format';

interface Props {
  grupos: IGrupo[];
  areas: IArea[];
}

const UsuarioStepper2: React.FC<Props> = (props: Props) => {
  const { grupos, areas } = props;
  const methods = useFormContext();
  const grupoId = methods.watch('idGrupo');
  const tipoConsultorB2b = methods.watch('tipoConsultorB2B');
  const tipoConsultorB2c = methods.watch('tipoConsultorB2C');


  return (
    <>
      <Controller
        control={methods.control}
        name='cargo'
        render={({ field }) => (
          <FormControl fullWidth variant='outlined' sx={{ marginTop: '20px' }}>
            <TextFieldStato
              {...field}
              type='tel'
              value={field.value}
              label='Cargo'
            />
          </FormControl>
        )}
      />

      <Controller
        control={methods.control}
        name='celular'
        render={({ field: { ref, ...field } }) => (
          <FormControl
            id='celular'
            fullWidth
            variant='outlined'
            sx={{ marginTop: '20px' }}
          >
            <TextFieldStato
              {...field}
              type='tel'
              inputRef={ref}
              value={field.value}
              id='celular'
              label='Celular'
            />
          </FormControl>
        )}
      />

      <GridItemCadastro item xs={12} md={12}>
        <Controller
          control={methods.control}
          name='nivelHierarquico'
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginTop: '20px' }}>
              <InputLabel id='demo-simple-select-label'>
                Nível Hierarquico
              </InputLabel>
              <SelectStato {...field} label='Nível Hierarquico'>
                {Object.keys(NivelHierarquico).map((nivel: string, index: number) => (
                  <MenuItem value={nivel} key={index}>
                    {NivelHierarquico[nivel as keyof typeof NivelHierarquico]}
                  </MenuItem>
                ))}
              </SelectStato>
            </FormControl>
          )}
        />
      </GridItemCadastro>

      <GridItemCadastro item xs={12} md={12}>
        <Controller
          control={methods.control}
          name='idGrupo'
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Grupos (Departamentos)
              </InputLabel>
              <SelectStato {...field} label='Grupos (Departamentos)'>
                {grupos.map((grupo: IGrupo, index: number) => (
                  <MenuItem value={grupo.id} key={index}>
                    {grupo.nome}
                  </MenuItem>
                ))}
              </SelectStato>
            </FormControl>
          )}
        />
      </GridItemCadastro>

      {grupos.find((f) => f.id === grupoId)?.nome.toLowerCase() ===
        'comercial' && (
          <GridItemCadastro item xs={12} md={12}>
            <Controller
              control={methods.control}
              name='modeloComercial'
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby='radio-buttons-modeloComercial-label'
                  name='radio-buttons-modeloComercial'
                >
                  <FormControlLabel
                    value={ModeloComercial.B2B}
                    control={<Radio />}
                    label={ModeloComercial.B2B}
                  />
                  <FormControlLabel
                    value={ModeloComercial.B2C}
                    control={<Radio />}
                    label={ModeloComercial.B2C}
                  />
                </RadioGroup>
              )}
            />
          </GridItemCadastro>
        )}

      {grupos.find((f) => f.id === grupoId)?.nome.toLowerCase() ===
        'mesa de mercado' && (
          <GridItemCadastro item xs={12} md={12}>
            <Controller
              control={methods.control}
              name='idArea'
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Áreas</InputLabel>
                  <SelectStato {...field} label='Areas'>
                    {areas.map((area: any, index: number) => (
                      <MenuItem value={area} key={index}>
                        {area.nome}
                      </MenuItem>
                    ))}
                  </SelectStato>
                </FormControl>
              )}
            />
          </GridItemCadastro>
        )}

      {grupos.find((f) => f.id === grupoId)?.nome.toLowerCase() ===
        'consultores' && (<>

          <GridItemCadastro container item xs={12} md={12}>
            <Grid item xs={6} sm={2} md={2}>
              <Controller
                control={methods.control}
                name='tipoConsultorB2C'
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      justifyContent: 'flex-end'
                    }}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="B2C" />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={2} md={2}>
              <Controller
                control={methods.control}
                name='tipoConsultorB2B'
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      justifyContent: 'flex-end'
                    }}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="B2B" />
                )}
              />
            </Grid>

          </GridItemCadastro>

          <GridItemCadastro container item xs={12} sm={12} md={12}>
            {tipoConsultorB2c && <>
              <Grid item xs={12} sm={6} md={6} sx={{ padding: "5px" }}>
                <Controller
                  control={methods.control}
                  name='valorHoraB2C'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <NumericFormat
                        {...field}
                        onChange={(e) => console.debug(e.target.value)}
                        onValueChange={(e) => field.onChange(e.value)}
                        prefix='R$'
                        thousandSeparator='.'
                        decimalSeparator=','
                        label='Valor hora B2C'
                        customInput={TextFieldStato}
                        valueIsNumericString
                        allowLeadingZeros={false}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </>}

            {tipoConsultorB2b && <>
              <Grid item xs={12} sm={6} md={6} sx={{ padding: "5px" }}>
                <Controller
                  control={methods.control}
                  name='valorHoraB2B'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <NumericFormat
                        {...field}
                        onChange={(e) => console.debug(e.target.value)}
                        onValueChange={(e) => field.onChange(e.value)}
                        prefix='R$'
                        thousandSeparator='.'
                        decimalSeparator=','
                        label='Valor hora B2B'
                        customInput={TextFieldStato}
                        valueIsNumericString
                        allowLeadingZeros={false}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </>}
          </GridItemCadastro>

          <GridItemCadastro item xs={12} md={12}>
            <Controller
              control={methods.control}
              name='tipoConsultor'
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  aria-labelledby='radio-buttons-modeloComercial-label'
                  name='radio-buttons-tipoConsultor'
                >
                  {Object.keys(TipoConsultor).map((tipo: string) => (
                    <FormControlLabel
                      value={TipoConsultor[tipo as keyof typeof TipoConsultor]}
                      control={<Radio />}
                      label={tipo}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </GridItemCadastro>
        </>
        )}
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default UsuarioStepper2;
