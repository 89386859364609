export enum ModeloComercial {
  B2B = 'B2B',
  B2C = 'B2C',
}

// TODO: Renomear, não é tipo consultor, e sim as BUs
export enum TipoConsultor {
  Prime = 'Prime',
  Exec = 'Exec',
  Profissional = 'Profissional',
}

export enum NivelHierarquico {
  Diretor = 'Diretor',
  Gestor = 'Gestor',
  CoordenadorSupervisor = 'CoordenadorSupervisor',
  Analista = 'Analista',
}

export enum StatusReuniaoEnum {
  PENDENTE = 'PENDENTE',
  AGENDADA = 'AGENDADA',
  PENDENTE_APROVACAO = 'PENDENTE_APROVACAO',
  APROVADA = 'APROVADA',
  CANCELADA = 'CANCELADA',
  NO_SHOW = 'NO_SHOW',
}
