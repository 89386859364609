import { useBaseActions } from '../../base.actions';
import { usuariosEmpresasAtom } from './UsuarioEmpresaState';

function useUsuariosEmpresasActions() {
  const baseActions = useBaseActions('usuariosEmpresas', usuariosEmpresasAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useUsuariosEmpresasActions };
