import { idiomaAtom } from './IdiomaState';
import { useBaseActions } from '../../../base.actions';

function useIdiomaConfigActions() {
  const baseActions = useBaseActions('configIdiomas', idiomaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useIdiomaConfigActions };
