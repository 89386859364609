import { styled } from 'styled-components';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  IPermissaoModulo,
  IdDescricao,
  IdNome,
} from '../../../../../commons/genericTypes';
import { DataGrid, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  ButtonStato,
  GridContainerCenter,
  SelectStato,
} from '../../../../../commons/styleds';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useModuloActions } from '../../../../../states/config/CadastroUsuarios/modulos/modulo.actions';

const permissionamento: IdDescricao[] = [
  {
    id: 1,
    descricao: 'Registro',
    ativo: true,
  },
  {
    id: 2,
    descricao: 'Exclusao',
    ativo: true,
  },
  {
    id: 3,
    descricao: 'Visualizacao',
    ativo: true,
  },
];

const UsuarioStepper3: React.FC = () => {
  const methods = useFormContext();
  const permissoes: IPermissaoModulo[] = methods.watch('permissoes');
  const [moduloId, setModuloId] = useState<number>();
  const [permissao, setPermissao] = useState<string>();
  const [usuarioPermissoes, setUsuarioPermissoes] = useState<any[]>([]);
  const [modulos, setModulos] = useState<IdNome[]>([]);
  const moduloActions = useModuloActions();

  useEffect(() => {
    moduloActions
      .list()
      .then((resp: any) => setModulos(resp))
      .catch((err: any) => toast.warn(err));

    setUsuarioPermissoes(permissoes);
  }, []);

  const columnsPermissionamento: GridColDef[] = [
    {
      field: 'tbModuloId',
      headerName: 'Modulo',
      width: 135,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let t = params.row as IPermissaoModulo;
        return <>{modulos.find((m) => m.id === t.tbModuloId)?.nome}</>;
      },
    },
    {
      field: 'role',
      headerName: 'Permissão',
      width: 135,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let t = params.row as IPermissaoModulo;
        return <>{t.role} </>;
      },
    },
    {
      field: 'Ação',
      headerName: 'Ação',
      width: 60,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let t = params.row as IPermissaoModulo;
        return (
          <IconButton
            sx={{ cursor: 'pointer' }}
            onClick={() => handleDeletePermissaoUsuarioId(t.tbModuloId!)}
          >
            {' '}
            <DeleteIcon />{' '}
          </IconButton>
        );
      },
    },
  ];

  const handleChangeModuloId = (event: any) => {
    setModuloId(event.target.value as number);
  };

  const handleChangePermissaoId = (event: any) => {
    setPermissao(event.target.value as string);
  };

  const handleDeletePermissaoUsuarioId = (tbModuloId: number) => {
    const sub = usuarioPermissoes.filter((f) => f.tbModuloId !== tbModuloId);
    setUsuarioPermissoes(sub);

    methods.setValue(
      'permissoes',
      permissoes.filter((f) => f.tbModuloId !== tbModuloId)
    );
  };

  const handleClickAddPermissao = () => {
    const index = usuarioPermissoes.findIndex(p => p.tbModuloId === moduloId);
    if (
      moduloId !== undefined &&
      moduloId! > 0 &&
      permissao !== undefined &&
      permissao !== '' &&
      index === -1
    ) {
      const newList = usuarioPermissoes.concat({
        tbModuloId: moduloId!,
        role: permissao!.toUpperCase(),
      });

      setUsuarioPermissoes(newList);
      methods.setValue('permissoes', newList);
    } else {
      toast.warn('Selecione uma opção válida nos combos');
    }
  };

  return (
    <>
      <GridCadastro
        container
        id='cadastro-usuario'
        sx={{ padding: '0px !important', width: '100% !important' }}
      >
        <GridItemCadastro item xs={12} md={4} sx={{ marginTop: '20px' }}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Menus</InputLabel>
            <SelectStato
              label='Modulos'
              value={moduloId}
              onChange={handleChangeModuloId}
            >
              {modulos &&
                modulos.map((modulo: IdNome, index: number) => (
                  <MenuItem value={modulo.id} key={index}>
                    {modulo.nome}
                  </MenuItem>
                ))}
            </SelectStato>
          </FormControl>
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={5} sx={{ marginTop: '20px' }}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Permissões</InputLabel>
            <SelectStato
              label='Permissoes'
              value={permissao}
              onChange={handleChangePermissaoId}
            >
              {permissionamento &&
                permissionamento.map(
                  (permissao: IdDescricao, index: number) => (
                    <MenuItem value={permissao.descricao} key={index}>
                      {permissao.descricao}
                    </MenuItem>
                  )
                )}
            </SelectStato>
          </FormControl>
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={2}>
          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            }}
          >
            <ButtonAdd onClick={handleClickAddPermissao} variant='contained'>
              +
            </ButtonAdd>
          </FormControl>
        </GridItemCadastro>

        <GridItemCadastro item xs={12}>
          <Controller
            control={methods.control}
            name="administrador"
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'flex-end'
                }}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } 
                label="Administrador" />
            )} />
        </GridItemCadastro>

        <Grid item xs={12}>
          <Box
            sx={{
              height: '30vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.tbModuloId}
                rows={usuarioPermissoes ?? []}
                columns={columnsPermissionamento}
                hideFooter={true}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </GridCadastro>
    </>
  );
};

const ButtonAdd = styled(ButtonStato)`
  min-width: 11px !important;
  margin-top: 15px !important;
`;

const GridCadastro = styled(GridContainerCenter)`
  width: 97% !important;
  margin-right: 0px !important;
  padding: 15px 15px;
`;

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export default UsuarioStepper3;
