import { useBaseActions } from '../../base.actions';
import { relatoriosAtom } from './RelatoriosState';

function useRelatoriosActions() {
  const baseActions = useBaseActions('relatorios', relatoriosAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    // update: baseActions.update,
    // create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useRelatoriosActions };
