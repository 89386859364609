import {
  Drawer,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { ButtonStato, IconDrawer } from '../../../../commons/styleds';
import InputArquivo from '../../../../components/InputArquivo/InputArquivo';
import { useState } from 'react';

interface IDrawerImportB2B {
  open: boolean;
  loading: boolean;
  obj: any;
  setObj: Function;
  onClose: Function;
}

const DrawerImportB2B: React.FC<IDrawerImportB2B> = (props) => {
  const { open, loading, onClose, obj, setObj } = props;
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [arquivoUrl, setArquivoUrl] = useState('');

  const abrirArquivo = () => {
    const link = document.createElement('a');
    link.href = 'https://stato-one-sandbox.s3.amazonaws.com/ExemploContatosB2B.xlsx';
    link.click();
  };

  return (
    <Drawer
      style={{ minWidth: '60vh' }}
      anchor={'right'}
      open={open}
      onClose={() => onClose(false)}
    >
      <Grid
        container
        xs={12}
        md={12}
        rowSpacing={3}
        style={{
          padding: '90px 30px',
          maxWidth: '700px',
          width: '60vh',
          height: '100%',
          alignContent: 'flex-start',
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item xs={2} md={2}>
            <IconDrawer onClick={() => onClose(false)}>close</IconDrawer>
          </Grid>
          <Grid item xs={10} md={10}>
            <Typography variant='h5'>
              {'Importar arquivo com os Contatos'}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ButtonStato variant="outlined" onClick={abrirArquivo} sx={{ marginTop: '10px' }}>
              Download arquivo base
          </ButtonStato>
        </Grid>

        <Grid item xs={12}>
          <InputArquivo
            isEdit={false}
            newObj={obj} 
            setNewObj={setObj} 
            nameFile={nomeArquivo} 
            setNameFile={setNomeArquivo} 
            urlFile={arquivoUrl} 
            setUrlFile={setArquivoUrl}
            acceptTypes={{ 
              'application/vnd.ms-excel': ['xls', 'xlsx'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xls', 'xlsx'],
            }}
          />
        </Grid>

        {loading && <LinearProgress />}

        <Grid
          item
          xs={12}
        >
          <ButtonStato variant='contained' onClick={() => onClose()}>
            Fechar
          </ButtonStato>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default DrawerImportB2B;
