import React, { useEffect, useState } from 'react';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from 'styled-components';

interface IEditorHtmlProps {
  setAsHtml: Function;
  initialHtml?: string;
  label?: string;
}

const EditorHtml: React.FC<IEditorHtmlProps> = (props) => {
  const { setAsHtml, initialHtml, label } = props;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    if (initialHtml === undefined)
      return;

    const contentBlock = htmlToDraft(initialHtml);
    if (!contentBlock)
      return;

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [])

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    setAsHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
      <DivEditorHtmlStato>
        <p style={{ color: '#666' }}>{label}</p>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </DivEditorHtmlStato>
    );
}

const DivEditorHtmlStato = styled.div`
  border: 1px solid #cbcbcb;
  border-radius: 20px !important;
  padding: 2px 15px 20px;
  &:hover {
    border: 1px solid #333333;
  }

  &:focus-within {
    border: 2px solid #118bd7;
  }

  >.wrapperClassName {
    >.toolbarClassName {
      border: 1px solid #cbcbcb;
      border-radius: 20px 20px 5px 5px !important;

      &:hover {
        border: 1px solid #333333;
      }
    }

    >.editorClassName {
      border: 1px solid #cbcbcb;
      border-radius: 5px 5px 20px 20px !important;
      min-height: 100px;
      padding: 2px 8px;
      content: 'asdfaef';

      &:hover {
        border: 1px solid #333333;
      }
    }
  }
`;

export default EditorHtml;
