import { etapaAtom } from '../../Programas/etapas/EtapaState';
import { useBaseActions } from '../../../base.actions';

function useEtapaActions() {
  const baseActions = useBaseActions('etapas', etapaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useEtapaActions };
