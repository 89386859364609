import { prazoAtom } from '../prazos/PrazoState';
import { useBaseActions } from '../../../base.actions';

function usePrazoActions() {
  const baseActions = useBaseActions('prazos', prazoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { usePrazoActions };
