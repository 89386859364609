import { styled } from 'styled-components';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Alert,
  Drawer,
  Typography,
  Icon,
  InputLabel,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { IIdiomaContato, INivelFluencia } from '../../../../commons/genericTypes';
import { IIdioma } from '../../../../commons/genericTypes';
import { ButtonStato, GridContainer, GridContainerCenter, IconDrawer, IconTituloStato, SelectStato, TextFieldStato } from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { possuiPermissao } from '../../../../commons/validaPermissao';
import { useIdiomaConfigActions } from '../../../../states/config/ConfiguracaoSistema/idiomas/idioma.actions';
import { useIdiomaActions } from '../../../../states/crm/contatoAuxiliares/idiomas/idioma.actions';
import { useNivelDeCargoActions } from '../../../../states/config/ConfiguracaoSistema/niveisDeCargos/nivelDeCargo.actions';
import { useNivelFluenciaConfigActions } from '../../../../states/config/ConfiguracaoSistema/nivelFluencia/nivelFluencia.actions';
import ButtonAdd from '../ButtonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';

interface Props {
  novoUsuario: boolean
}

export interface IParams {
  id?: string;
}

const IdiomaComponent: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const { id } = useParams<IParams>();

  const auth = useRecoilValue(authAtom);
  const idiomaConfigActions = useIdiomaConfigActions();
  const nivelFluenciaActions = useNivelFluenciaConfigActions();
  const idiomaActions = useIdiomaActions();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    handleEditar(params.row);
  };

  const [open, setOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idiomasContato, setIdiomasContato] = useState<IIdiomaContato[]>([]);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);

  // SELECT Fiels
  const [idiomasSelect, setIdiomasSelect] = useState<IIdioma[]>([]);
  const [niveisFluencias, setNiveisFluencias] = useState<INivelFluencia[]>([]);
  const [idiomaSelected, setIdiomaSelected] = useState<number>();
  const [nivelFluenciaSelected, setNivelFluenciaSelected] = useState<number>();

  const columns: GridColDef[] = [
    {
      field: 'idIdioma',
      headerName: 'Idioma',
      width: 190,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IIdiomaContato;
        return idiomasSelect?.find((f) => f.id === obj.idIdioma)?.nome;
      },
    },
    {
      field: 'idNivelFluencia',
      headerName: 'Grau de Fluência',
      width: 200,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IIdiomaContato;
        return niveisFluencias?.find((f) => f.id === obj.idNivelFluencia)?.nome;
      },
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IIdiomaContato;
        return (
          <>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleEditar(idiomasContato.find(f => f.id === obj.id!)!)}
            >
              {' '}
              <EditIcon />{' '}
            </Icon>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleApagar(obj)}
            >
              {' '}
              <DeleteIcon />{' '}
            </Icon>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    idiomaConfigActions
      .list()
      .then((resp: IIdioma[]) => setIdiomasSelect(resp))
      .catch((err: any) => toast.warn(err));

    nivelFluenciaActions
      .list()
      .then((resp: INivelFluencia[]) => setNiveisFluencias(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  useEffect(() => {
    if (!id) return;
    idiomaActions
      .list(false, `?contatoId=${id}`)
      .then((resp: IIdiomaContato[]) => setIdiomasContato(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, [id]);

  const handleEditar = (idioma: IIdiomaContato) => {
    setIdiomaSelected(idioma.idIdioma);
    setNivelFluenciaSelected(idioma.idNivelFluencia);
    setIdAtualizar(idioma.id);
    setOpen(true);
  };

  const openDrawerIdioma = (result: any) => {
    setIdiomaSelected(undefined);
    setNivelFluenciaSelected(undefined);
    setIdAtualizar(undefined);
    setOpen(result);
  };

  const handleApagar = (obj: IIdiomaContato) => {
    idiomaActions.delete(obj.id!)
      .then(() => {
        const sub = idiomasContato.filter((f) => f.idIdioma !== obj.idIdioma);
        setIdiomasContato(sub);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Erro ao apagar');
      })
  };

  const adicionar = () => {
    if (Number(id) <= 0) {
      toast.warn('Id do usuário inválido.');
      return;
    }

    const data: IIdiomaContato = {
      idIdioma: idiomaSelected,
      idContato: Number(id),
      idNivelFluencia: nivelFluenciaSelected
    }

    if (!idAtualizar) adicionarNovo(data);
    else {
      data.id = idAtualizar;
      editarIdioma(data);
    }
  };

  const adicionarNovo = (data: IIdiomaContato) => {
    idiomaActions.create(data!)
      .then(data => {
        const newList = idiomasContato.concat(data!);
        setIdiomasContato(newList);
        openDrawerIdioma(false);
        toast.success('Adicionado com sucesso');
      })
      .finally(() => setLoading(false));
  }

  const editarIdioma = (data: IIdiomaContato) => {
    idiomaActions.update(data.id!, data)
      .then(data => {
        const newList = idiomasContato.filter((f) => f.id !== data.id);
        setIdiomasContato(newList.concat(data));
        openDrawerIdioma(false);
        toast.success('Adicionado com sucesso');
      })
      .finally(() => setLoading(false));
  }

  return <>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          height: '250px',
          width: '100%',
        }}
      >
        <ThemeProvider theme={createTheme({}, ptBR)}>
          <DataGrid
            getRowId={(row) => `${row?.idContato}_${row?.idNivelFluencia}_${row?.idIdioma}`}
            rows={idiomasContato ?? []}
            columns={columns}
            loading={loading}
            sx={{
              backgroundColor: 'white',
              border: '1px solid black',
              borderRadius: '14px',
            }}
          />
        </ThemeProvider>

        <ButtonAdd onClick={() => openDrawerIdioma(true)} />

        <Drawer
          style={{ minWidth: '250px' }}
          anchor={'right'}
          open={open}
          onClose={() => openDrawerIdioma(false)}
        >
          <Grid item xs={12} md={12} style={{ padding: "90px 30px", maxWidth: "500px", width: "auto" }}>

            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: "center", marginBottom: "10px" }}>
              <Grid item xs={2} md={2}>
                <IconDrawer onClick={() => openDrawerIdioma(false)}>close</IconDrawer>
              </Grid>
              <Grid item xs={10} md={10}>
                <Typography variant="h5">{'Adicionar Idioma na lista'}</Typography>
              </Grid>
            </Grid>

            <FormControl required fullWidth variant='outlined' sx={{ margin: "10px 0px" }}>
              <InputLabel id='demo-simple-select-label'>
                Idioma
              </InputLabel>
              <SelectStato value={idiomaSelected} onChange={(e: any) => setIdiomaSelected(e.target.value)} required label='Consultor'>
                {idiomasSelect &&
                  idiomasSelect!.map(
                    (idiomaSelect: IIdioma, index: number) => (
                      <MenuItem value={idiomaSelect.id} key={index}>
                        {idiomaSelect.nome}
                      </MenuItem>
                    )
                  )}
              </SelectStato>
            </FormControl>

            <FormControl required fullWidth variant='outlined' sx={{ margin: "10px 0px" }}>
              <InputLabel id='demo-simple-select-label'>
                Grau de Fluência
              </InputLabel>
              <SelectStato value={nivelFluenciaSelected} onChange={(e: any) => setNivelFluenciaSelected(e.target.value)} required label='Grau de Fluência'>
                {niveisFluencias &&
                  niveisFluencias!.map(
                    (grau: INivelFluencia, index: number) => (
                      <MenuItem value={grau.id} key={index}>
                        {grau.nome}
                      </MenuItem>
                    )
                  )}
              </SelectStato>
            </FormControl>


            <GridItemCadastroBtnSalvar item xs={11} md={11} sx={{
              position: "fixed",
              right: "0px",
              bottom: "0px"
            }}>
              <ButtonStato variant="contained" onClick={adicionar}>Adicionar</ButtonStato>
            </GridItemCadastroBtnSalvar>
          </Grid>
        </Drawer >
      </Box >
    </Grid >
  </>;
}

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default IdiomaComponent;
