import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  LinearProgress,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomTabPanel,
  a11yProps,
} from './../../MiniBio/accordionAndTabPanel';
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { BasePage, Container } from "..";
import { IEmpresa } from "../../../../commons/genericTypes";
import { ButtonStato, GridTopPage, IconTituloStato, TextFieldStato } from "../../../../commons/styleds";
import { useEmpresasActions } from "../../../../states/empresas/empresa.actions";
import { history } from '../../../../helpers/history';
import { useParams } from "react-router-dom";
import { IParams } from "../../MiniBio/Endereco";
import DadosEmpresa from "./Dados";
import UsuariosEmpresa from "./Usuarios";

interface INovaEmpresa {
  idAtualizar?: number;
}

const DetalhesEmpresa: React.FC<INovaEmpresa> = ({ idAtualizar }) => {
  const { id } = useParams<IParams>();
  const [value, setValue] = useState<number>(0);
  const [empresa, setEmpresa] = useState<IEmpresa>();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: "flex-end"
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant='h5'>Empresas {empresa && <span> - {empresa.razaosocial}</span>}</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('/crm/empresa')}
            type='submit'
            variant='contained'
          >
            <Icon>undo</Icon>
            Voltar
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Grid
        item
        xs={12}
        md={12}
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          padding: '0px 41px',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              margin: '0px 25px !important',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label='basic tabs example'
            >
              <Tab label='Dados' {...a11yProps(0)} />
              <Tab label='Usuários' {...a11yProps(1)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <DadosEmpresa empresa={empresa} setEmpresa={setEmpresa} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <UsuariosEmpresa />
          </CustomTabPanel>
        </Box>
      </Grid>
    </BasePage>
  )
}

export default DetalhesEmpresa;
