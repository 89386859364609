import { areaPosicaoAtom } from './AreaPosicaoState';
import { useBaseActions } from '../base.actions';

function useAreaPosicaoActions() {
  const baseActions = useBaseActions('areaPosicoes', areaPosicaoAtom);

  return {
    get,
    list: baseActions.list
  };

  async function get(salvarNoState: boolean = false) {
    return await baseActions.list(salvarNoState);
  }
}

export { useAreaPosicaoActions };
