import { useBaseActions } from '../../base.actions';
import { tipoEventoAtom } from './TipoEventoState';


function useTipoEventoActions() {
  const baseActions = useBaseActions('TipoEvento', tipoEventoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useTipoEventoActions };
