import { FormControl, Box, Typography } from "@mui/material"
import { ButtonStato } from "../../commons/styleds"
import { useDropzone } from 'react-dropzone'
import { useCallback } from "react";

interface IParams {
    isEdit?: any; 
    objOnEdit?: any; 
    setObjOnEdit?: any;
    newObj?: any;
    setNewObj?: any;
    nameFile?: any;
    setNameFile?: any;
    urlFile?: any;
    setUrlFile?: any;
    acceptTypes?: any;
    propriedade: any;
}

const InputArquivoMethods: React.FC<IParams> = (props) => {

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            const file = acceptedFiles[0];

            if (file) {
                props.setNameFile(file.name);
                props.setUrlFile(URL.createObjectURL(file));

                props.isEdit ?
                props.setObjOnEdit.setValue(props.propriedade, file ) :
                props.setNewObj.setValue(props.propriedade, file );
            }
        },[props]
    );

    const abrirArquivo = () => {
        const link = document.createElement('a');
        link.href = props.urlFile;
        link.download = props.nameFile;
        link.click();
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: props.acceptTypes
    });

    return (
        <FormControl fullWidth sx={{ marginTop: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ marginBottom: '8px' }}>Anexo</Typography>
            <Box {...getRootProps()} sx={dropzoneStyles}>
                <input {...getInputProps()} />
                <Typography>
                {props.nameFile
                    ? `Arquivo carregado: ${props.nameFile}`
                    : 'Arraste e solte um arquivo aqui ou clique para selecionar'}
                </Typography>
                {props.urlFile && (
                <ButtonStato variant="outlined" onClick={abrirArquivo} sx={{ marginTop: '10px' }}>
                    Abrir Arquivo
                </ButtonStato>
                )}
            </Box>
            </Box>
        </FormControl>
    )
};

const dropzoneStyles = {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default InputArquivoMethods;