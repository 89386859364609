import { useBaseActions } from '../../base.actions';
import { conteudoAtom } from './Conteudo';


function useConteudoActions() {
  const baseActions = useBaseActions('Conteudo', conteudoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
    createFormData: baseActions.createFormData
  };
}

export { useConteudoActions };
