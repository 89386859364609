import { useBaseActions } from '../../base.actions';
import { tbAreaAtom } from './TbAreaState';

function useTbAreaActions() {
  const baseActions = useBaseActions('Areas', tbAreaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useTbAreaActions };
