import { useBaseActions } from '../../base.actions';
import { timesheetConsultorAtom } from './TimsheetConsultorState';

function useTimesheetConsultorActions() {
  const baseActions = useBaseActions('relatorios/timesheet-consultor', timesheetConsultorAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useTimesheetConsultorActions };
