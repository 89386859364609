import { GridContainer } from "../../commons/styleds";
import React from "react";

const MesaMercado: React.FC = () => {
  return (
    <GridContainer id="home"
      container
      style={{
        display: "flex",
        alignContent: "space-around",
        justifyContent: "space-around",
        zIndex: 999999
      }}
    >
      <h1>Mesa de Mercado [Em Construção]!</h1>
    </GridContainer>
  );
};

export default MesaMercado;
