import { useBaseActions } from "../../../base.actions";
import { contatoHistoricoEmpresaAtom } from "./ContatoHistoricoEmpresaState";

function useContatoHistoricoEmpresaActions() {
  const baseActions = useBaseActions('ContatoHistoricoEmpresas', contatoHistoricoEmpresaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  }
}

export { useContatoHistoricoEmpresaActions };

