import { useBaseActions } from '../base.actions';
import { grupoAtom } from './GrupoState';

function useGrupoActions() {
  const baseActions = useBaseActions('grupos', grupoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useGrupoActions };
