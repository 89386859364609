import { styled, useTheme } from 'styled-components';
import Menu from '../../../../components/FerramentasAdministrativas/Menu';
import { ButtonStato, GridContainer, TextFieldStato } from '../../../../commons/styleds';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import MultiSelect from '../../../../components/Generics/MultiSelect';
import { IConfigArea, IConfigSubArea, IdDescricao } from '../../../../commons/genericTypes';
import { useConfigAreaActions } from '../../../../states/config/ConfiguracaoSistema/areas/configArea.actions';
import { useConfigSubAreaActions } from '../../../../states/config/ConfiguracaoSistema/subAreas/configSubArea.actions';
import { DataGrid, GridCellParams, GridColDef, GridEventListener, ptBR } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Icon } from '@mui/material';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { history } from '../../../../helpers/history';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { possuiPermissao } from '../../../../commons/validaPermissao';

const empty: IConfigArea = {
  descricao: '',
  ativo: true,
  idSubAreas: [],
  subAreas: []
};

const Areas: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const configAreaActions = useConfigAreaActions();
  const configSubAreaActions = useConfigSubAreaActions();

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [areas, setAreas] = useState<IConfigArea[]>([]);
  const [areaTemp, setAreaTemp] = useState<IConfigArea>(empty);
  const [subAreas, setSubAreas] = useState<IConfigSubArea[] | undefined>(undefined);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 70,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 300,
    },
    {
      field: 'subAreas',
      headerName: 'SubAreas',
      width: 220,
      valueGetter: (params: GridCellParams) => {
        const subAreas = params.row.idSubAreas as IConfigSubArea[];
        const descricoes = subAreas.map((subArea) => subArea.descricao);
        const mostrar = descricoes.join(' ; ');
        return mostrar;
      }
    },
    {
      field: 'action',
      headerName: ' ',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IConfigArea;
        return <Icon onClick={() => handleClickOpen(obj)} style={{ color: "gray", cursor: "pointer" }}>delete</Icon>;
      },
    },
  ]

  const deleteValue = () => {
    configAreaActions.delete(areaTemp.id!).then((resp: IConfigArea[]) => {
      const sub = areas.filter((f) => f.id !== areaTemp.id);
      setAreas(sub);
      atualizarFormulario(empty);
    }).catch((err: any) => toast.warn(err));

    methods.reset(areaTemp, { keepDefaultValues: true });
    setIdAtualizar(areaTemp.id);
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const onSubmit = async (data: any) => {
    data.idSubAreas = subAreas?.filter((subArea) => data.subAreas.includes(subArea.id));

    if (data.id > 0)
      AtualizarUsuario(data);
    else
      configAreaActions
        .create(data)
        .then((result: IConfigArea) => {
          try {
            if (result.id != undefined) {
              setAreas(areas.concat(result));
              atualizarFormulario(empty);
              toast.success('Cadastro realizado com sucesso');
            }
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });
  };

  const HandleAtivoInativo = (area: any) => {
    area.ativo = area.ativo ? false : true;
    AtualizarUsuario(area)
    atualizarFormulario(empty);
  };

  const AtualizarUsuario = (data: any) => {
    configAreaActions
      .update(data.id, data)
      .then((result: IConfigArea) => {
        try {
          if (result.id != undefined) {
            const configAreas = areas.filter((a) => a.id !== result.id);
            setAreas(configAreas.concat(result));
            atualizarFormulario(empty);
            toast.success('Atualizado com sucesso');
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    configSubAreaActions
      .list()
      .then((resp: IConfigSubArea[]) => setSubAreas(resp))
      .catch((err) => toast.warn(err));

    configAreaActions
      .list()
      .then((resp: IConfigArea[]) => setAreas(resp))
      .catch((err) => toast.warn(err));

    setLoading(false);
  }, [])

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });

    if (result.idSubAreas.length > 0)
      methods.setValue('subAreas', result.idSubAreas.map((m: IdDescricao) => m.id));

    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setAreaTemp(obj)
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
    setAreaTemp(empty);
  };

  return (
    <BasePage>
      <Menu />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Tem certeza que dejesa excluir esse item ?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color='error' onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato id='formArea' onSubmit={methods.handleSubmit(onSubmit)}>
                <h3>
                  {idAtualizar ? `Atualizar, id: ${idAtualizar}` : 'Novo Cadastro'}
                </h3>
                <Controller
                  control={methods.control}
                  name='descricao'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Descricao'
                      />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='subAreas'
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                      {subAreas &&
                        <MultiSelect
                          opcoes={subAreas as IdDescricao[]}
                          setValor={onChange}
                          valor={value ?? []}
                          label="SubAreas"
                        />}
                    </FormControl>
                  )}
                />

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Controller
                    control={methods.control}
                    name="ativo"
                    render={({ field }) => (
                      <FormControlLabelStato control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                          defaultChecked
                        />
                      } label="Ativo" />
                    )}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type='submit' variant='contained'>
                    Salvar
                  </ButtonStato>
                  {idAtualizar ? (
                    <ButtonStato
                      color='error'
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant='contained'
                    >
                      Cancelar
                    </ButtonStato>
                  ) : (
                    <></>
                  )}
                </div>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box sx={{
            height: '60vh',
            width: '100%',
          }}>
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={areas}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px'
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default Areas;



