import { useBaseActions } from '../../base.actions';
import { perfilCompetenciasAtom } from './PerfilCompetenciaState';

function usePerfilCompetenciasActions() {
  const baseActions = useBaseActions('resultadoRealizacaoCompetencias', perfilCompetenciasAtom);
 
  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { usePerfilCompetenciasActions };
