import { FormControl } from '@mui/material';
import { GridItemCadastro, GridItemLinhaCadastro } from '..';
import { PhoneInputStato, TextFieldStato } from '../../../../commons/styleds';
import { useContatoActions } from '../../../../states/crm/contatos/contato.actions';
import { IContato } from '../../../../commons/genericTypes';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

interface IContatoCampos {
  email: string;
  setEmail: Function;
  nome: string;
  setNome: Function;
  telefone: string;
  setTelefone: Function;
  ultimoCargo: string;
  setUltimoCargo: Function;
  ultimoSalario: string;
  setUltimoSalario: Function;
  salarioPretendido: number | undefined;
  setSalarioPretendido: Function;
  veioDoBanco: boolean;
  setVeioDoBanco: Function;
  novoUsuario: boolean;
  setNovoUsuario: Function;
  b2b: boolean;
}

const ContatoCampos: React.FC<IContatoCampos> = (props) => {
  const {
    setEmail,
    email,
    setNome,
    nome,
    setTelefone,
    telefone,
    setUltimoCargo,
    ultimoCargo,
    setUltimoSalario,
    ultimoSalario,
    setSalarioPretendido,
    salarioPretendido,
    setVeioDoBanco,
    veioDoBanco,
    setNovoUsuario,
    b2b
  } = props;
  const contatoActions = useContatoActions();

  const onChangeEmail = (event: any) => {
    const email = event.target.value;
    setEmail(email);
    if (email.indexOf('@') < 1) return;

    contatoActions
      .list(email)
      .then((resp: IContato[]) => {
        if (resp.length < 0) return;

        if (resp[0].email === email) {
          setEmail(resp[0].email!);
          setNome(resp[0].nome ?? '');
          setSalarioPretendido(resp[0].salarioPretendido ?? 0);
          setTelefone(resp[0].telefone ?? '');
          setUltimoCargo(resp[0].ultimoCargo ?? '');
          setUltimoSalario(resp[0].ultimaRemuneracao ?? '');
          setVeioDoBanco(true);
          setNovoUsuario(false);
        } else if (veioDoBanco) {
          setVeioDoBanco(false);
          setNome('');
          setSalarioPretendido(0);
          setTelefone('');
          setUltimoCargo('');
          setUltimoSalario('');
          setNovoUsuario(true);
        }
      })
      .catch((err: any) => toast.warn(err));
  };

  return (
    <>
      <GridItemLinhaCadastro item xs={12} md={12}>
        <GridItemCadastro item xs={12} md={5}>
          <FormControl required fullWidth variant='outlined'>
            <TextFieldStato
              onChange={(e) => onChangeEmail(e)}
              value={email}
              type='email'
              required={!b2b}
              label='Email'
            />
          </FormControl>
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={4}>
          <FormControl required fullWidth variant='outlined'>
            <TextFieldStato
              onChange={(e) => setNome(e.target.value)}
              value={nome}
              type='nomeCompleto'
              label='Nome Completo'
              disabled={veioDoBanco}
              required={!b2b}
            />
          </FormControl>
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={3}>
          <FormControl required fullWidth variant='outlined'>
            <PhoneInputStato
              onChange={(e) => setTelefone(e)}
              inputProps={{ maxLength: 17 }}
              value={telefone}
              defaultCountry='BR'
            />
          </FormControl>
        </GridItemCadastro>
      </GridItemLinhaCadastro>
    </>
  );
};

export default ContatoCampos;
