import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  ptBR,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import {
  IComanda,
  IComandaEtapaReunioes,
  IConsultor,
  IEtapa,
  IOnboarding,
  IStatus,
  IUsuario,
} from '../../../../../commons/genericTypes';
import { authAtom } from '../../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../../helpers/history';
import { useParams } from 'react-router-dom';
import { useComandaActions } from '../../../../../states/crm/comandas/comanda.actions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { CheckBox } from '@mui/icons-material';
import { styled } from 'styled-components';
import Divider from '@mui/material/Divider';
import { NumericFormat } from 'react-number-format';

interface IOnboardingProps {
  consultorOnboardings: IConsultor[];
  comanda: IComanda;
}

const Onboarding: React.FC<IOnboardingProps> = (props) => {
  const { consultorOnboardings, comanda } = props;

  const getTempoTotal = (horaInicio: string, horaFim: string) => {
    let horaTotal = '';
    // duração de uma jornada normal de trabalho (em minutos)
    let hInicio = parse(horaInicio);
    // quantidade de minutos efetivamente trabalhados
    let hFim = parse(horaFim);

    // diferença entre as jornadas
    let diff = Math.abs(hFim - hInicio);
    if (diff != 0) {
      let horas = Math.floor(diff / 60);
      let minutos = diff - horas * 60;
      horaTotal = horas + ':' + (minutos > 9 ? minutos : '0' + minutos);
    }

    return horaTotal;
  };

  function parse(horario: any) {
    // divide a string em duas partes, separado por dois-pontos, e transforma em número
    let [hora, minuto] = horario.split(':').map((v: any) => parseInt(v));
    if (!minuto) {
      // para o caso de não ter os minutos
      minuto = 0;
    }
    return minuto + hora * 60;
  }

  return (
    <>
      <Grid item xs={12} sm={4} md={2} lg={1.5}>
        <Typography
          variant='h6'
          component='h6'
        >
          Onboarding:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={10} sx={{ display: 'flex' }}>
        <Grid item xs={12} sm={4}>
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>Consultor:</Typography>
          <Typography variant='subtitle1'>
            {
              consultorOnboardings.find(
                (f: IConsultor) =>
                  f.id === comanda?.idConsultorOnboarding
              )?.nome
            }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>Data:</Typography>
          <Typography variant='subtitle1'>
            {new Date(
              comanda.comandaEtapaReunioes
                ?.find((f: any) => f.onboarding)
                ?.dtPrevista?.toString()!
            ).toLocaleDateString()}
          </Typography>
          <Typography variant='subtitle1'>
            {'De ' + new Date(
              comanda.comandaEtapaReunioes
                ?.find((f: any) => f.onboarding)
                ?.dtRealizadaInicio!.toString()!
            ).toLocaleTimeString().substring(0, 5)}
          </Typography>
          <Typography variant='subtitle1'>
            {'Até ' + new Date(
              comanda.comandaEtapaReunioes
                ?.find((ff: any) => ff.onboarding)
                ?.dtRealizadaFim!.toString()!
            ).toLocaleTimeString().substring(0, 5)}
          </Typography>
          <Typography variant='subtitle1'>
            {'Total ' + getTempoTotal(
              new Date(
                comanda.comandaEtapaReunioes
                  ?.find((f: any) => f.onboarding)
                  ?.dtRealizadaInicio!.toString()!
              ).toLocaleTimeString(),
              new Date(
                comanda.comandaEtapaReunioes
                  ?.find((ff: any) => ff.onboarding)
                  ?.dtRealizadaFim!.toString()!
              ).toLocaleTimeString()
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>Status:</Typography>
          <Typography variant='subtitle1'>
            {
              comanda.comandaEtapaReunioes
                ?.find((ff: any) => ff.onboarding)?.status
            }
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Onboarding;
