import { useBaseActions } from '../base.actions';
import { empresasAtom } from './EmpresaState';

function useEmpresasActions() {
  const baseActions = useBaseActions('empresas', empresasAtom);
 
  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useEmpresasActions };
