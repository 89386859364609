import { IAuth } from './genericTypes';

export const possuiPermissao = (auth: IAuth | null, permissao: string[]) => {
  return (
    (auth?.administrador ||
      auth?.modulos?.some(
        (m) => m.modulo !== undefined && permissao.includes(m.modulo)
      )) ??
    false
  );
};
