import { Router } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from "@mui/material";
import { history } from './helpers/history';

const outerTheme = createTheme({
  palette: {
    primary: {
      dark: "rgb(21, 31, 59)",
      light: "rgb(8, 70, 124)",
      main: "rgb(17, 139, 215)",
    },
    success: {
      dark: "rgb(18, 63, 28)",
      light: "rgb(28, 111, 41)",
      main: "rgb(117, 178, 29)",
    },
    secondary: {
      main: "rgb(70, 70, 70)",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={outerTheme}>
      <Router history={history}>
        <Routes />
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
