import { styled } from 'styled-components';
import {
  ButtonStato,
  GridContainer,
  SelectStato,
  TextFieldStato,
} from '../../../commons/styleds';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { history } from '../../../helpers/history';
import { possuiPermissao } from '../../../commons/validaPermissao';
import HeaderPages from '../../../components/Generics/HeaderPages';
import { IEvento, ITipoEvento } from '../../../commons/genericTypes';
import { useEventoActions } from '../../../states/conteudos/eventos/evento.actions';
import EditorHtml from '../../../components/EditorHtml/EditorHtml';
import { useTipoEventoActions } from '../../../states/conteudos/tipoEventos/tipoEvento.actions';
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(utc);
dayjs.extend(timezone);

const empty: IEvento = {
  id: 0,
  titulo: '',
  descricao: '',
  visivel: true,
  ativo: true,
};


const Eventos: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const eventoActions = useEventoActions();
  const tipoEventoActions = useTipoEventoActions();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    params.row.descricao = params.row.descricao ?? "";
    params.row.dtInicio = dayjs.tz(params.row.dtInicio) ?? "";
    params.row.dtFim = dayjs.tz(params.row.dtFim) ?? "";

    atualizarFormulario(params.row);
  };

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [eventos, setEventos] = useState<IEvento[]>([]);
  const [tipoEventos, setTipoEventos] = useState<ITipoEvento[]>([]);
  const [eventoTemp, setEventoTemp] = useState<IEvento>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'idTipoEvento',
      headerName: 'Tipo de Evento',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return tipoEventos?.find((f) => f.id === obj.idTipoEvento)?.nome;
      },
    },
    {
      field: 'palestrante',
      headerName: 'Palestrante',
      width: 150,
    },
    {
      field: 'titulo',
      headerName: 'Titulo',
      width: 150,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 150,
    },
    {
      field: 'ordem',
      headerName: 'Posição',
      width: 100,
    },
    {
      field: 'dtInicio',
      headerName: 'Data de Início',
      width: 200,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return new Date(obj.dtInicio!).toLocaleDateString();
      },
    },
    {
      field: 'dtFim',
      headerName: 'Data de Termino',
      width: 200,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return new Date(obj.dtFim!).toLocaleDateString();
      },
    },
    {
      field: 'link',
      headerName: 'Link do Evento',
      width: 150,
    },
    {
      field: 'bu',
      headerName: 'BU',
      width: 100,
    },
    {
      field: 'visivel',
      headerName: 'Visivel Portal Assessorado',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return <Checkbox
          checked={obj.visivel}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return <Icon onClick={() => handleClickOpen(obj)} style={{ color: "gray", cursor: "pointer" }}>delete</Icon>;
      },
    },
  ];


  const deleteValue = () => {
    eventoActions.delete(eventoTemp!.id!).then((resp: IEvento[]) => {
      const sub = eventos.filter((f) => f.id !== eventoTemp!.id);
      setEventos(sub);
      atualizarFormulario(empty);
      toast.success('Apagado com sucesso !');

      methods.reset(eventoTemp, { keepDefaultValues: true });
      setIdAtualizar(eventoTemp!.id);
      setLoadingForm(true);
      setOpen(false);
      setTimeout(() => {
        setLoadingForm(false);
      }, 1500);
    }).catch((err: any) => toast.warn(err));

  };

  const AtualizarUsuario = (data: any) => {
    eventoActions
      .update(data.id, data)
      .then((result: IEvento) => {
        const sub = eventos.filter((f) => f.id !== result.id);
        setEventos(sub.concat(result));
        atualizarFormulario(empty);
        toast.success('Atualizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };


  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    if (data.id > 0)
      AtualizarUsuario(data);
    else {
      eventoActions
        .create(data)
        .then((result: IEvento) => {
          setEventos(eventos.concat(result));
          atualizarFormulario(empty);
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });
    }

    setLoadingSave(false);
  };

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    eventoActions
      .list()
      .then((resp: IEvento[]) => setEventos(resp))
      .catch((err: any) => toast.warn(err));

    tipoEventoActions
      .list()
      .then((resp: ITipoEvento[]) => setTipoEventos(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setEventoTemp(obj)
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
  };

  return (
    <BasePage>
      <HeaderPages
        actionBack={false}
        arrowBackClick='/crm/contatos'
        icon='ballot_icon'
        title='Eventos'
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Tem certeza que dejesa excluir esse item ?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color='error' onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato
                id='formEvento'
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <h3>
                  {idAtualizar
                    ? `Atualizar - ID: ${idAtualizar}`
                    : 'Novo Cadastro'}
                </h3>

                <Controller
                  control={methods.control}
                  name='idTipoEvento'
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>Tipo de Evento</InputLabel>
                      <SelectStato {...field} required label='Tipo de Evento'>
                        {tipoEventos.map((tipoEvento: ITipoEvento, index: number) => (
                          <MenuItem value={tipoEvento.id} key={index}>
                            {tipoEvento.nome}
                          </MenuItem>
                        ))}
                      </SelectStato>
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='palestrante'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Palestrante'
                      />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='titulo'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Titulo'
                      />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='ordem'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        type='number'
                        inputRef={ref}
                        value={field.value}
                        label='Posição'
                      />
                    </FormControl>
                  )}
                />

                <Grid item xs={12} md={4} sx={{ paddingTop: "20px" }}></Grid>

                <Controller
                  control={methods.control}
                  name='dtInicio'
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={{
                        clearButtonLabel: 'Empty',
                        todayButtonLabel: 'Now',
                      }}
                    >
                      <DesktopDatePicker
                        {...field}
                        label='Data Início'
                        format='DD/MM/YYYY'
                        slotProps={{
                          textField: {
                            required: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />

                <Grid item xs={12} md={4} sx={{ paddingTop: "20px" }}></Grid>

                <Controller
                  control={methods.control}
                  name='dtFim'
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={{
                        clearButtonLabel: 'Empty',
                        todayButtonLabel: 'Now',
                      }}
                    >
                      <DesktopDatePicker
                        {...field}
                        label='Data Fim'
                        format='DD/MM/YYYY'
                        slotProps={{
                          textField: {
                            required: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='link'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Link para Evento'
                      />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='bu'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='BU'
                      />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='descricao'
                  render={({ field: { ref, ...field } }) => (
                    <div style={{ paddingTop: '20px' }}>
                      <EditorHtml setAsHtml={field.onChange} initialHtml={field.value} label={'Descrição'} />
                    </div>
                  )}
                />

                {/* <Controller
                  control={methods.control}
                  name='descricao'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Descricao'
                      />
                    </FormControl>
                  )}
                /> */}


                <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={12} md={4} sm={4}>
                    <Controller
                      control={methods.control}
                      name='ativo'
                      render={({ field }) => (
                        <FormControlLabelStato
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              inputProps={{ 'aria-label': 'controlled' }}
                            //defaultChecked={true}
                            />
                          }
                          label='Ativo'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={8}>
                    <Controller
                      control={methods.control}
                      name='visivel'
                      render={({ field }) => (
                        <FormControlLabelStato
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              inputProps={{ 'aria-label': 'controlled' }}
                            //defaultChecked={true}
                            />
                          }
                          label='Visivel Portal Assessorado'
                        />
                      )}
                    />
                  </Grid>

                </Grid>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type='submit' variant='contained'>
                    Salvar
                  </ButtonStato>
                  {idAtualizar ? (
                    <ButtonStato
                      color='error'
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant='contained'
                    >
                      Cancelar
                    </ButtonStato>
                  ) : (
                    <></>
                  )}
                </div>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={eventos}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 10px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default Eventos;
