import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldStato } from '../../../../../commons/styleds';
import React from 'react';

interface Props {
  novoUsuario: boolean;
}

const UsuarioStepper1: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  return (
    <>
      <Controller
        control={methods.control}
        name='nome'
        render={({ field: { ref, ...field } }) => (
          <FormControl
            id='nome'
            required
            fullWidth
            variant='outlined'
            sx={{ marginTop: '20px' }}
          >
            <TextFieldStato
              {...field}
              id={'nomeInput'}
              type='text'
              inputRef={ref}
              value={field.value}
              label='Nome'
            />
          </FormControl>
        )}
      />

      <Controller
        control={methods.control}
        name='email'
        render={({ field }) => (
          <FormControl
            required
            fullWidth
            variant='outlined'
            sx={{ marginTop: '20px' }}
          >
            <TextFieldStato
              {...field}
              id={'emailInput'}
              type='email'
              label='Email'
              disabled={!novoUsuario}
            />
          </FormControl>
        )}
      />

      {novoUsuario && (
        <>
          <Controller
            control={methods.control}
            name='senha'
            render={({ field }) => (
              <FormControl
                required
                fullWidth
                variant='outlined'
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato {...field} type='password' label='senha' />
              </FormControl>
            )}
          />

          <Controller
            control={methods.control}
            name='confirmarSenha'
            render={({ field }) => (
              <FormControl
                required
                fullWidth
                variant='outlined'
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato
                  {...field}
                  type='password'
                  label='Confirmar Senha'
                />
              </FormControl>
            )}
          />
        </>
      )}
    </>
  );
};

export default UsuarioStepper1;
