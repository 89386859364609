import { configSegmentoAtom } from './ConfigSegmentoState';
import { useBaseActions } from '../../../base.actions';

function useConfigSegmentoActions() {
  const baseActions = useBaseActions('configSegmentos', configSegmentoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useConfigSegmentoActions };
