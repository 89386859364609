import { useBaseActions } from "../../../base.actions";
import { nivelPosicaoAtom } from "./NivelPosicaoState";

function useNivelPosicaoActions() {
  const baseActions = useBaseActions('nivelPosicao', nivelPosicaoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useNivelPosicaoActions };

