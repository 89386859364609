import { styled } from 'styled-components';
import Menu from '../../../../components/FerramentasAdministrativas/Menu';
import {
  ButtonStato,
  GridContainer,
  SelectStato,
  TextFieldStato,
} from '../../../../commons/styleds';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Stepper,
  StepLabel,
  Step,
  Typography,
  Button,
  Box,
  InputLabel,
  MenuItem
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IGrupoTrabalho, IPlano, IPrograma, IResponsavel, IStatus } from '../../../../commons/genericTypes';
import { useProgramaActions } from '../../../../states/config/Programas/programas/programa.actions';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MultiSelect from '../../../../components/Generics/MultiSelect';
import { history } from '../../../../helpers/history';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { possuiPermissao } from '../../../../commons/validaPermissao';


const empty: IPrograma = {
  nome: '',
  idGrupoTrabalho: 0,
  idPlano: 0,
  ativo: true,
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 80,
  },
  {
    field: 'descricao',
    headerName: 'Descrição',
    width: 500,
  },
  {
    field: 'ativo',
    headerName: 'Ativo',
    width: 100,
    editable: true,
    renderCell: (params: GridCellParams) => {
      let configArea = params.row as IPrograma;
      return <Checkbox disabled checked={configArea.ativo} />;
    },
  },
];

const steps = ['', '', ''];

const Programa: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const auth = useRecoilValue(authAtom);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const methods = useForm();
  const programaActions = useProgramaActions();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [programas, setProgramas] = useState<IPrograma[]>([]);
  const [planos, setPlanos] = useState<IPlano[]>([
    {
      id: 2,
      nome: "Programa Transição de Carreira - Pessoa Física Teste",
      ativo: true,
    },
    {
      id: 1,
      nome: "OutPlacement - Plano Grupo",
      ativo: true,
    },
    {
      id: 1,
      nome: "Executivo Sênior",
      ativo: true,
    },
    {
      id: 1,
      nome: "Executivo Pleno",
      ativo: true,
    },
  ]);

  const [selectedResponsavelDiretores, setSelectedResponsavelDiretores] = useState<IResponsavel[] | undefined>(undefined);
  const [selectedResponsavelConsultores, setSelectedResponsavelConsultores] = useState<IResponsavel[] | undefined>(undefined);
  const [selectedResponsavelConsultoresSenior, setSelectedResponsavelConsultoresSenior] = useState<IResponsavel[] | undefined>(undefined);
  const [selectedResponsavelAssistentes, setSelectedResponsavelAssistentes] = useState<IResponsavel[] | undefined>(undefined);
  const [selectedResponsavelDiretores2, setSelectedResponsavelDiretores2] = useState<IResponsavel[] | undefined>(undefined);

  const [responsaveis, setResponsaveis] = useState<IResponsavel[]>([
    { id: 2, descricao: "Admin", ativo: true, },
    { id: 2, descricao: "Fabio ", ativo: true, },
    { id: 2, descricao: "Bruna Farabulini", ativo: true, },
    { id: 2, descricao: "Mayara Lopes", ativo: true, },
    { id: 2, descricao: "Karina Freitas", ativo: true, },
    { id: 2, descricao: "Lucia Costa", ativo: true, },
    { id: 2, descricao: "Pascoal Muniz", ativo: true, },
    { id: 2, descricao: "Rosana Cruz", ativo: true, },
    { id: 2, descricao: "Giselle Brighenti", ativo: true, },
    { id: 2, descricao: "Aline Benachio", ativo: true, },
    { id: 2, descricao: "Rebeca Terrengui Vieira", ativo: true, },
    { id: 2, descricao: "Nathalia Iwaniec", ativo: true, },
    { id: 2, descricao: "Nathalie Ohl", ativo: true, },
    { id: 2, descricao: "Maira Horta", ativo: true, },
    { id: 2, descricao: "Elisa Russo", ativo: true, },
    { id: 2, descricao: "Talissa Mattiazzi", ativo: true, },
    { id: 2, descricao: "Neiva Gonçalves", ativo: true, },
    { id: 2, descricao: "Flavia Rodrigues", ativo: true, },
    { id: 2, descricao: "Fernanda Palma", ativo: true, },
    { id: 2, descricao: "Hugo Capobianco", ativo: true, },
    { id: 2, descricao: "Antônio Fiorenza", ativo: true, },
    { id: 2, descricao: "Willian Kadekari", ativo: true, },
    { id: 2, descricao: "Alice Bartels", ativo: true, },
    { id: 2, descricao: "Selma Fredo", ativo: true, },
    { id: 2, descricao: "Rogério Lara", ativo: true, },
    { id: 2, descricao: "Angela Levandowski", ativo: true, },
    { id: 2, descricao: "Marina Costa", ativo: true, },
    { id: 2, descricao: "Carlos Alvim", ativo: true, },
    { id: 2, descricao: "Heloisa Gontijo", ativo: true, },
    { id: 2, descricao: "Kaue Carvalho", ativo: true, },
    { id: 2, descricao: "Patrícia Epperlein", ativo: true, },
    { id: 2, descricao: "Bruna Marques", ativo: true, },
    { id: 2, descricao: "Natalia Pachioni", ativo: true, },
    { id: 2, descricao: "Aline Pereira", ativo: true, },
    { id: 2, descricao: "MONICA RIGACCI DE ALENCAR MACHADO", ativo: true, },
    { id: 2, descricao: "Joaquim.alves", ativo: true, },
    { id: 2, descricao: "Malu Mello", ativo: true, },
    { id: 2, descricao: "gisele.fujimoto", ativo: true, },
    { id: 2, descricao: "Gladys Las Casas", ativo: true, },
    { id: 2, descricao: "Mariana Mozena", ativo: true, },
    { id: 2, descricao: "Rosana Souza", ativo: true, },
    { id: 2, descricao: "Ana Vilas Boas", ativo: true, },
    { id: 2, descricao: "Aloisio Buoro", ativo: true, },
    { id: 2, descricao: "Rodrigo Formigoni", ativo: true, },
    { id: 2, descricao: "Sabrina Mendonça", ativo: true, },
    { id: 2, descricao: "Ana Souza", ativo: true, },
    { id: 2, descricao: "Gilberto Castro", ativo: true, },
    { id: 2, descricao: "Heitor Peixoto", ativo: true, },
    { id: 2, descricao: "Frederico Vani", ativo: true, },
    { id: 2, descricao: "Caroline.Leão", ativo: true, },
    { id: 2, descricao: "Thiago.Basile", ativo: true, },
    { id: 2, descricao: "Fabio Loli", ativo: true, },
    { id: 2, descricao: "Juliana.Palermo", ativo: true, },
    { id: 2, descricao: "Regina Dorriguello", ativo: true, },
    { id: 2, descricao: "Leonardo.Pereira", ativo: true, },
    { id: 2, descricao: "Ilton.Mello", ativo: true, },
    { id: 2, descricao: "Ricardo.Guerra", ativo: true, },
    { id: 2, descricao: "Melissa.Costa", ativo: true, },
    { id: 2, descricao: "Raquel Teixeira", ativo: true, },
    { id: 2, descricao: "Fabiana Reis", ativo: true, },
    { id: 2, descricao: "Renata.Ortlepp", ativo: true, },
    { id: 2, descricao: "Bruno Machado", ativo: true, },
    { id: 2, descricao: "Cristina Dumani", ativo: true, },
    { id: 2, descricao: "Caio Cardoso", ativo: true, },
    { id: 2, descricao: "Claudia Rodriguez", ativo: true, },
    { id: 2, descricao: "Leticia Castanheira", ativo: true, },
    { id: 2, descricao: "Willian Costa", ativo: true, },
    { id: 2, descricao: "Gabriela Ferreira", ativo: true, },
    { id: 2, descricao: "Carolina Pascoal", ativo: true, },
    { id: 2, descricao: "Monalisa Santana", ativo: true, },
    { id: 2, descricao: "Stella Lazzuri", ativo: true, },
    { id: 2, descricao: "Daniele Riera", ativo: true, },
    { id: 2, descricao: "Gabriel Toschi", ativo: true, },
    { id: 2, descricao: "Maysa Gomes", ativo: true, },
    { id: 2, descricao: "Maysa Ramos", ativo: true, },
    { id: 2, descricao: "Cristina Pogetti", ativo: true, },
    { id: 2, descricao: "Bruna Rodrigues", ativo: true, },
    { id: 2, descricao: "Clarice.Santana", ativo: true, },
    { id: 2, descricao: "Gislaine Gomes", ativo: true, },
    { id: 2, descricao: "Gustavo Vicenze", ativo: true, },
    { id: 2, descricao: "Cesar Gomes", ativo: true, },
    { id: 2, descricao: "Suzana Piccoli", ativo: true, },
    { id: 2, descricao: "Silvana Piccoli", ativo: true, },
    { id: 2, descricao: "Vânia Gonçalves", ativo: true, },
    { id: 2, descricao: "Neusa Rodriguez", ativo: true, },
    { id: 2, descricao: "Flavia Barrack", ativo: true, },
    { id: 2, descricao: "Lilian Ruppel", ativo: true, },
  ]);
  const [selectedIdPlano, setSelectedIdPlano] = useState<number>(0);
  const [grupoTrabalhos, setGrupoTrabalhos] = useState<IGrupoTrabalho[]>([
    {
      id: 1,
      nome: "Grupo de Trabalho - BH",
      ativo: true,
    },
    {
      id: 2,
      nome: "Grupo de Trabalho - RJ",
      ativo: true,
    },
    {
      id: 3,
      nome: "Grupo de Trabalho I",
      ativo: true,
    },
    {
      id: 4,
      nome: "Grupo de Trabalho II",
      ativo: true,
    },
  ]);
  const [status, setStatus] = useState<IStatus[]>([
    {
      id: 1,
      nome: "Em Outplacement",
      ativo: true,
    },
    {
      id: 2,
      nome: "Recolocado",
      ativo: true,
    },
    {
      id: 3,
      nome: "Em Shopping",
      ativo: true,
    },
    {
      id: 4,
      nome: "Reincidente ",
      ativo: true,
    },
  ]);
  const [selectedIdGrupoTrabalho, setSelectedIdGrupoTrabalho] = useState<number>(0);
  const [selectedProgramas, setSelectedProgramas] = useState<
    IPrograma[] | undefined
  >(undefined);

  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    // if (data.id > 0)
    //   programaActions
    //     .update(data.id, data)
    //     .then((result: IPrograma) => {
    //       const sub = programas.filter((f) => f.id !== result.id);
    //       setProgramas(sub.concat(result));
    //       atualizarFormulario(empty);
    //       toast.success('Atualizado com sucesso');
    //       //TODO: limpar formulário
    //     })
    //     .catch(() => {
    //       toast.error('Erro ao Atualizar!');
    //     });
    // else
    //   programaActions
    //     .create(data)
    //     .then((result: IPrograma) => {
    //       setProgramas(programas.concat(result));
    //       atualizarFormulario(empty);
    //       toast.success('Cadastro realizado com sucesso');
    //       //TODO: limpar formulário
    //     })
    //     .catch(() => {
    //       toast.error('Erro ao Cadastrar!');
    //     });

    setLoadingSave(false);
  };

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    programaActions
      .list()
      .then((resp: IPrograma[]) => setProgramas(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  return (
    <BasePage>
      <Menu />

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato
                id='formArea'
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <h3>
                  {idAtualizar
                    ? `Atualizar - ID: ${idAtualizar}`
                    : 'Novo Cadastro'}
                </h3>


                {/* 
                <Controller
                  control={methods.control}
                  name='nome'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Nome'
                      />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name='valor'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Valor'
                      />
                    </FormControl>
                  )}
                />

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Controller
                    control={methods.control}
                    name='ativo'
                    render={({ field }) => (
                      <FormControlLabelStato
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                          //defaultChecked={true}
                          />
                        }
                        label='Ativo'
                      />
                    )}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type='submit' variant='contained'>
                    Salvar
                  </ButtonStato>
                  {idAtualizar ? (
                    <ButtonStato
                      color='error'
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant='contained'
                    >
                      Cancelar
                    </ButtonStato>
                  ) : (
                    <></>
                  )}
                </div>
              </FormStato> */}

                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>

                    {activeStep === 1 ? <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          control={methods.control}
                          name='dataShopping'
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>

                              <DatePicker
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Data Shopping"
                              />
                            </FormControl>
                          )}
                        />


                        <Controller
                          control={methods.control}
                          name='dataInícioDoPrograma'
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                              <DatePicker
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Data Início do Programa"
                              />

                            </FormControl>
                          )}
                        />

                        <Controller
                          control={methods.control}
                          name='dataDeTérminoDoPrograma'
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                              <DatePicker
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Data de Término do Programa"
                              />

                            </FormControl>
                          )}
                        />

                        <Controller
                          control={methods.control}
                          name='dataDeExtensão'
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                              <DatePicker
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Data de Extensão"
                              />

                            </FormControl>
                          )}
                        />

                      </LocalizationProvider>

                    </> : activeStep === 2 ? <>

                      <Controller
                        control={methods.control}
                        name='diretoresResponsaveis'
                        render={({ field: { ref, ...field } }) => (
                          <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                            {responsaveis &&
                              <MultiSelect
                                opcoes={responsaveis as IResponsavel[]}
                                setValor={setSelectedResponsavelDiretores}
                                valor={selectedResponsavelDiretores || []}
                                label="Diretores Responsáveis"
                              />}
                          </FormControl>
                        )}
                      />

                      <Controller
                        control={methods.control}
                        name='consultoresSeniorResponsaveis
                        '
                        render={({ field: { ref, ...field } }) => (
                          <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                            {responsaveis &&
                              <MultiSelect
                                opcoes={responsaveis as IResponsavel[]}
                                setValor={setSelectedResponsavelConsultoresSenior}
                                valor={selectedResponsavelConsultoresSenior || []}
                                label="Consultores Senior Responsáveis                                "
                              />}
                          </FormControl>
                        )}
                      />

                      <Controller
                        control={methods.control}
                        name='consultoresResponsaveis'
                        render={({ field: { ref, ...field } }) => (
                          <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                            {responsaveis &&
                              <MultiSelect
                                opcoes={responsaveis as IResponsavel[]}
                                setValor={setSelectedResponsavelConsultores}
                                valor={selectedResponsavelConsultores || []}
                                label="Consultores Responsáveis"
                              />}
                          </FormControl>
                        )}
                      />

                      <Controller
                        control={methods.control}
                        name='assistentesResponsaveis'
                        render={({ field: { ref, ...field } }) => (
                          <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                            {responsaveis &&
                              <MultiSelect
                                opcoes={responsaveis as IResponsavel[]}
                                setValor={setSelectedResponsavelAssistentes}
                                valor={selectedResponsavelAssistentes || []}
                                label="Assistentes Responsáveis"
                              />}
                          </FormControl>
                        )}
                      />

                      <Controller
                        control={methods.control}
                        name='diretoresResponsaveis'
                        render={({ field: { ref, ...field } }) => (
                          <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                            {responsaveis &&
                              <MultiSelect
                                opcoes={responsaveis as IResponsavel[]}
                                setValor={setSelectedResponsavelDiretores2}
                                valor={selectedResponsavelDiretores2 || []}
                                label="Diretores Responsáveis"
                              />}
                          </FormControl>
                        )}
                      />

                    </> : <>

                      <Controller
                        control={methods.control}
                        name='nome'
                        render={({ field: { ref, ...field } }) => (
                          <FormControl required fullWidth variant='outlined' sx={{ 'marginTop': '20px' }}>
                            <FormInput
                              {...field}
                              inputRef={ref}
                              value={field.value}
                              label='Nome'
                            />
                          </FormControl>
                        )}
                      />

                      <Controller
                        control={methods.control}
                        name="idPlano"
                        render={({ field }) => (
                          <FormControl fullWidth sx={{ 'marginTop': '20px' }}>
                            <InputLabel id="demo-simple-select-label">Plano</InputLabel>
                            <SelectStato
                              {...field}
                              label="Plano"
                            >
                              {planos.map((plano: IPlano, index: number) => (
                                <MenuItem value={plano.id} key={index}>{plano.nome}</MenuItem>
                              ))}

                            </SelectStato>
                          </FormControl>
                        )} />

                      <Controller
                        control={methods.control}
                        name="idGrupoTrabalho"
                        render={({ field }) => (
                          <FormControl fullWidth sx={{ 'marginTop': '20px' }}>
                            <InputLabel id="demo-simple-select-label">Grupo de Trabalho</InputLabel>
                            <SelectStato
                              {...field}
                              label="Grupo de Trabalho"
                            >
                              {grupoTrabalhos.map((grupoTrabalho: IGrupoTrabalho, index: number) => (
                                <MenuItem value={grupoTrabalho.id} key={index}>{grupoTrabalho.nome}</MenuItem>
                              ))}

                            </SelectStato>
                          </FormControl>
                        )} />

                      <Controller
                        control={methods.control}
                        name="idStatus"
                        render={({ field }) => (
                          <FormControl fullWidth sx={{ 'marginTop': '20px' }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <SelectStato
                              {...field}
                              label="Status"
                            >
                              {status.map((Statu: IStatus, index: number) => (
                                <MenuItem value={Statu.id} key={index}>{Statu.nome}</MenuItem>
                              ))}

                            </SelectStato>
                          </FormControl>
                        )} />

                    </>}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <ButtonStato
                        color="inherit"
                        variant='contained'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Voltar
                      </ButtonStato>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                        <ButtonStato color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Pular
                        </ButtonStato>
                      )}
                      <ButtonStato variant='contained' onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
                      </ButtonStato>
                    </Box>
                  </React.Fragment>
                )}

              </FormStato>
            </FormProvider>

          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={programas}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage >
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default Programa;
