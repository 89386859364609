import { FormControl, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IEmpresa } from "../../../../../commons/genericTypes";
import { GridContainer } from "../../../../../commons/styleds";
import { IParams } from "../../../MiniBio/Endereco";
import { FormInput } from "../../NovaEmpresa";
import { history } from '../../../../../helpers/history';
import { useEmpresasActions } from "../../../../../states/empresas/empresa.actions";

const empty: IEmpresa = {
  id: 0,
  cnpj: "",
  razaosocial: "",
  nomefantasia: "",
  matriz: false,
  ativo: true
};

interface IDadosEmpresa {
  empresa: IEmpresa | undefined;
  setEmpresa: Function;
}

const DadosEmpresa: React.FC<IDadosEmpresa> = ({ empresa, setEmpresa }) => {
  const methods = useForm<IEmpresa>({ defaultValues: empty });
  const { id } = useParams<IParams>();
  const empresaActions = useEmpresasActions();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (empresa) {
      methods.reset(empresa);
      return;
    }

    empresaActions.get(Number(id))
      .then((empresaResp) => {
        setTimeout(() => {
          setEmpresa(empresaResp);
          methods.reset(empresaResp);
        }, 1000
        );
      })
      .catch(() => {
        toast.error('Falha ao buscar empresa');
        // history.push('/crm/empresa')
      });
  }, [])

  return (
    <>
      <GridContainer container columnSpacing={2}>
        <FormProvider {...methods}>
          <Grid item xs={12} md={6}>
            <Controller
              control={methods.control}
              name='nomefantasia'
              render={({ field: { ref, ...field } }) => (
                <FormControl required fullWidth variant='outlined'>
                  <FormInput
                    {...field}
                    inputRef={ref}
                    label='Nome Fantasia'
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={methods.control}
              name='razaosocial'
              render={({ field: { ref, ...field } }) => (
                <FormControl required fullWidth variant='outlined'>
                  <FormInput
                    {...field}
                    inputRef={ref}
                    label='Razão social'
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={methods.control}
              name='cnpj'
              render={({ field: { ref, ...field } }) => (
                <FormControl required fullWidth variant='outlined'>
                  <FormInput
                    {...field}
                    inputRef={ref}
                    label='CNPJ'
                  />
                </FormControl>
              )}
            />
          </Grid>
        </FormProvider>
      </GridContainer>
    </>
  )
}

export default DadosEmpresa;

