import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from "../../base.actions";
import { comandaAtom } from "./ComandaState";


function useComandaActions() {
  const baseActions = useBaseActions('comandas', comandaAtom);
  
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    createContatosB2BFormData: createContatosB2BFormData,
  };

  async function createContatosB2BFormData(obj: object) {
    try {
      const baseUrl = `${process.env.REACT_APP_API_URL}/v1/comandas/b2b/import`;
      const recurso = await axiosWrapper.postFormData(`${baseUrl}`, obj);
      return recurso;
    } catch (e: any) {
      return {};
    }
  }
}

export { useComandaActions };
