import { useRecoilValue } from "recoil";
import { useUsuarioActions } from "../../states/config/CadastroUsuarios/usuarios/usuario.actions";
import { authAtom } from "../../states/config/CadastroUsuarios/usuarios/AuthState";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Logo from "./../../assets/Logo-Stato-Intoo_1.png";

import Button from "@mui/material/Button";
import FilledInput from "@mui/material/FilledInput";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  FormControlStato,
  GridContainer,
  GridItem,
  Title,
} from "../../commons/styleds";
import { styled } from "styled-components";
import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { history } from '../../helpers/history';

const Login: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const usuarioActions = useUsuarioActions();
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");

  const [mostrarSenha, setMostrarSenha] = React.useState(false);

  const handleClickMostrarSenha = () => setMostrarSenha((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    usuarioActions.login(email, senha).catch((erro) => {
      toast.error(erro);
    });
  };

  useEffect(() => {
    if (auth!!)
      history.push('/')
  }, [auth]);

  return (
    <GridContainer container>
      <GridItem item xs={12} md={6}>
        <img src={Logo} alt="logo" width={"60%"} />
      </GridItem>
      <GridItem item xs={12} md={6} color={"var(--blue2)"}>
        <Grid item xs={10} md={9}>
          <form onSubmit={onSubmit}>
            <GridItem item xs={12} md={12} color={"var(--blue2)"}>
              <TitleLogin>Faça login para acessar o sistema</TitleLogin>
              <FormControlStato
                sx={{ width: "100%", marginBottom: "15px" }}
                variant="filled"
              >
                <TextField
                  id="filled-basic"
                  label="Login"
                  type="email"
                  required
                  variant="filled"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                />
              </FormControlStato>
              <FormControlStato sx={{ width: "100%" }} variant="filled">
                <InputLabel htmlFor="senha">Senha</InputLabel>
                <FilledInput
                  id="senha"
                  type={mostrarSenha ? "text" : "password"}
                  value={senha}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSenha(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickMostrarSenha}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControlStato>
            </GridItem>
            <GridItem item
              xs={12}
              md={12}
              color={"var(--blue2)"}
              style={{ paddingTop: "15px" }}
            >
              <Grid item xs={6} md={6}>
                <Typography color={"primary.main"} variant="body2">
                  Esqueceu a senha?
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                <Button type="submit" variant="contained">
                  Login
                </Button>
              </Grid>
            </GridItem>
          </form>
        </Grid>
      </GridItem>
    </GridContainer>
  );
};

const TitleLogin = styled(Title)`
  color: white;
  text-align: center;
  @media (max-width: 900px) {
    color: var(--black);
  }
`;

export const SpanStatoLogin = styled.span`
  margin-top: 10px;
  font-size: 14px;
`;

export default Login;
