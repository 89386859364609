import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Drawer,
  Modal,
  Typography,
  Icon,
  LinearProgress,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import {
  ButtonStato,
  IconDrawer,
  SelectStato,
  TextFieldStato,
} from '../../../../../commons/styleds';
import { authAtom } from '../../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../../helpers/history';
import { useHistoricoAcoesActions } from '../../../../../states/crm/contatoAuxiliares/historicos/historicoAcoes.actions';
import { IAcoes, IHistoricoAcoes, ITipoDeAcao } from '../../../../../commons/genericTypes';
import { GridItemCadastro } from '../../../Comanda';
import { styled } from 'styled-components';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTipoDeAcaoActions } from '../../../../../states/config/ConfiguracaoSistema/tiposDeAcoes/tipoDeAcao.actions';
import dayjs from 'dayjs';
import InputArquivo from '../../../../../components/InputArquivo/InputArquivo';
import { possuiPermissao } from '../../../../../commons/validaPermissao';

const empty: IHistoricoAcoes = {
  id: 0,
  idTipo: 0,
  idComanda: 0,
  dtPrevista: undefined,
  observacoes: "",
  anexo: undefined,
  realizada: false,
  habilitarEmpresas: false,
  habilitarAssessorado: false,
  notificarMesa: false,
  notificarPool: false,
  ativo: false
};

interface IParams {
  contatoId?: string;
  comandaId?: number;
}

enum ActionType {
  Create,
  Edit
}

const HistoricoComponent: React.FC<IParams> = (props) => {
  const { contatoId, comandaId } = props;
  const auth = useRecoilValue(authAtom);
  const historicoAcoesActions = useHistoricoAcoesActions();
  const tipoDeAcaoActions = useTipoDeAcaoActions();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [historicos, setHistoricos] = useState<IHistoricoAcoes[]>([]);
  const [historico, setHistorico] = useState<IHistoricoAcoes>(empty);
  const [tiposDeAcoes, setTiposDeAcoes] = useState<ITipoDeAcao[]>([]);
  const [editAcao, setEditAcao] = useState<boolean>(false);
  const [acaoEmEdicao, setAcaoEmEdicao] = useState<IHistoricoAcoes>(empty);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [acaoDeleteId, setAcaoDeleteId] = useState<number>(0);
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [arquivoUrl, setArquivoUrl] = useState('');

  const columns: GridColDef[] = [
    {
      field: 'idTipo',
      headerName: 'Tipo',
      width: 200,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return tiposDeAcoes.find(tipo => tipo.id == obj.idTipo)?.nome;
      }
    },
    {
      field: 'dtPrevista',
      headerName: 'Data Prevista',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return obj.dtPrevista == null ? <></> : new Date(obj.dtPrevista!.toString()).toLocaleDateString();
      },
    },
    {
      field: 'realizada',
      headerName: 'Realizada',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return <Checkbox
          checked={obj.realizada}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'habilitarEmpresas',
      headerName: 'Habilitar Empresas',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return <Checkbox
          checked={obj.habilitarEmpresas}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'habilitarAssessorado',
      headerName: 'Habilitar para Assessorado',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return <Checkbox
          checked={obj.habilitarAssessorado}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'notificarCex',
      headerName: 'Notificar Cex',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return <Checkbox
          checked={obj.notificarCex}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'notificarMesa',
      headerName: 'Notificar Mesa',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return <Checkbox
          checked={obj.notificarMesa}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'notificarPool',
      headerName: 'Notificar Pool',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return <Checkbox
          checked={obj.notificarPool}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'observacoes',
      headerName: 'Observações:',
      width: 150,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistoricoAcoes;
        return (
          <>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => HandleApagar(obj.id!)}
            >
              {' '}
              <DeleteIcon />{' '}
            </Icon>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => HandleEditar(historicos.find(f => f.id === obj.id!))}
            >
              {' '}
              <EditIcon />{' '}
            </Icon>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    tipoDeAcaoActions
      .list()
      .then((resp: ITipoDeAcao[]) => setTiposDeAcoes(resp.filter(f => f.ativo)))
      .catch((err) => toast.warn(err));

    historicoAcoesActions
      .list(false, `?comandaId=${comandaId}`)
      .then((data: IHistoricoAcoes[]) => setHistoricos(data.filter(f => f.ativo)));

    setLoading(false);
  }, []);

  const openDrawerHistorico = (result: any) => {
    setHistorico(empty);
    setOpen(result);

    if (!result) {
      setEditAcao(false);
      setNomeArquivo('');
      setArquivoUrl('');
    }
  };

  const HandleApagar = (id: any) => {
    setAcaoDeleteId(id);
    setOpenDeleteModal(true);
  };

  const HandleEditar = (acaoEmEdicao: any) => {
    acaoEmEdicao!.dtPrevista = dayjs(new Date(acaoEmEdicao.dtPrevista));
    setEditAcao(true);
    setAcaoEmEdicao(acaoEmEdicao);
    openDrawerHistorico(true);
  }

  const deleteAcao = () => {
    setLoadingSave(true);

    if (acaoDeleteId !== 0) {
      historicoAcoesActions
        .delete(acaoDeleteId)
        .then((resp: IHistoricoAcoes) => {
          const acao = historicos.filter((f) => f.id !== resp.id);
          setHistoricos(acao);
          toast.success('Deletado com sucesso');
        }).catch((err: any) => {
          toast.warn('Erro ao excluir!');
        });
    }

    setOpenDeleteModal(false);
    setLoadingSave(false);
  }

  const submitAcao = (actionType: ActionType) => {
    setLoadingSave(true);


    if (actionType === ActionType.Create) {
      const data: any = {
        idTipo: historico.idTipo,
        idComanda: comandaId,
        dtPrevista: historico.dtPrevista,
        observacoes: historico.observacoes,
        anexoFile: historico.anexo,
        realizada: historico.realizada,
        habilitarEmpresas: historico.habilitarEmpresas,
        habilitarAssessorado: historico.habilitarAssessorado,
        notificarMesa: historico.notificarMesa,
        notificarPool: historico.notificarPool,
        ativo: true,
      }

      historicoAcoesActions
        .createFormData(data)
        .then((result: IHistoricoAcoes) => {
          setHistoricos(historicos.concat(result as IHistoricoAcoes));
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });
    }

    if (actionType === ActionType.Edit) {
      historicoAcoesActions
        .update(acaoEmEdicao.id!, acaoEmEdicao)
        .then((result: IHistoricoAcoes) => {
          const acao = historicos.filter((f) => f.id !== result.id);
          setHistoricos(acao.concat(result).sort((a, b) => a.id! - b.id!));
          toast.success('Atualizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Atualizar!');
        });
    }

    openDrawerHistorico(false);
    setLoadingSave(false);
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <Typography variant="subtitle1" sx={{ padding: "10px" }}>
            Histórico de Ações
          </Typography>
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id}_${Math.floor(Math.random() * 20)}`}
              rows={historicos ?? []}
              columns={columns}
              loading={loading}
              sx={{
                overflowX: 'scroll',
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <GridItemCadastroBtnSalvar
            item
            xs={11}
            md={11}
            sx={{
              position: 'relative',
              bottom: '53%',
              right: '6%',
              zIndex: '30',
              left: '8%',
            }}
          >
            <ButtonStato
              style={{
                padding: '0px',
                height: '35px',
                minWidth: '35px',
              }}
              onClick={() => openDrawerHistorico(true)}
              variant='contained'
            >
              +
            </ButtonStato>
          </GridItemCadastroBtnSalvar>
          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerHistorico(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerHistorico(false)}>
                    close
                  </IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant='h5' sx={{ paddingBottom: '20px' }}>
                    {editAcao ? 'Editar Ação' : 'Adicionar Ação'}
                  </Typography>
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Tipos de Ações</InputLabel>
                <SelectStato
                  required
                  value={editAcao ? acaoEmEdicao.idTipo : undefined}
                  label='Tipos de Ações'
                  onChange={(event: any) => {
                    editAcao ?
                      setAcaoEmEdicao({ ...acaoEmEdicao, idTipo: event.target.value }) :
                      setHistorico({ ...historico, idTipo: event.target.value });
                  }}
                >
                  {tiposDeAcoes?.map((acao: IAcoes, index: number) => {
                    return (
                      <MenuItem value={acao.id} key={index}>
                        {acao.nome}
                      </MenuItem>
                    );
                  })}
                </SelectStato>
              </FormControl>

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Realizada"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.realizada : historico.realizada}
                    onClick={() => {
                      editAcao ?
                        setAcaoEmEdicao({ ...acaoEmEdicao, realizada: !acaoEmEdicao.realizada }) :
                        setHistorico({ ...historico, realizada: !historico.realizada })
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Notificar Mesa"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.notificarMesa : historico.notificarMesa}
                    onClick={() => {
                      editAcao ?
                        setAcaoEmEdicao({ ...acaoEmEdicao, notificarMesa: !acaoEmEdicao.notificarMesa }) :
                        setHistorico({ ...historico, notificarMesa: !historico.notificarMesa })
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Notificar Pool"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.notificarPool : historico.notificarPool}
                    onClick={() => {
                      editAcao ?
                        setAcaoEmEdicao({ ...acaoEmEdicao, notificarPool: !acaoEmEdicao.notificarPool }) :
                        setHistorico({ ...historico, notificarPool: !historico.notificarPool })
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Habilitar Empresa"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.habilitarEmpresas : historico.habilitarEmpresas}
                    onClick={() => {
                      editAcao ?
                        setAcaoEmEdicao({ ...acaoEmEdicao, habilitarEmpresas: !acaoEmEdicao.habilitarEmpresas }) :
                        setHistorico({ ...historico, habilitarEmpresas: !historico.habilitarEmpresas })
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Habilitar Assessorado"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.habilitarAssessorado : historico.habilitarAssessorado}
                    onClick={() => {
                      editAcao ?
                        setAcaoEmEdicao({ ...acaoEmEdicao, habilitarAssessorado: !acaoEmEdicao.habilitarAssessorado }) :
                        setHistorico({ ...historico, habilitarAssessorado: !historico.habilitarAssessorado })
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Notificar Cex"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.notificarCex : historico.notificarCex}
                    onClick={() => {
                      editAcao ?
                        setAcaoEmEdicao({ ...acaoEmEdicao, notificarCex: !acaoEmEdicao.notificarCex }) :
                        setHistorico({ ...historico, notificarCex: !historico.notificarCex })
                    }}
                  />
                }
              />

              <FormControl
                required
                fullWidth
                variant='outlined'
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato
                  id={'obsInput'}
                  type='text'
                  label='Observação'
                  multiline
                  maxRows={4}
                  value={editAcao ? acaoEmEdicao.observacoes : historico?.observacoes}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    editAcao ?
                      setAcaoEmEdicao({ ...acaoEmEdicao, observacoes: event.target.value }) :
                      setHistorico({ ...historico, observacoes: event.target.value });
                  }}
                />
                {/* <Controller
                control={methods.control}
                name='texto'
                render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        <FormInput
                            id="filled-multiline-static"
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            multiline
                            maxRows={4}
                            label='Texto'
                        />
                    </FormControl>
                )}
            /> */}
              </FormControl>

              {!editAcao &&
                <InputArquivo
                  isEdit={editAcao}
                  objOnEdit={acaoEmEdicao}
                  setObjOnEdit={setAcaoEmEdicao}
                  newObj={historico}
                  setNewObj={setHistorico}
                  nameFile={nomeArquivo}
                  setNameFile={setNomeArquivo}
                  urlFile={arquivoUrl}
                  setUrlFile={setArquivoUrl}
                />
              }


              {loadingSave && <LinearProgress />}

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant='contained' onClick={() => submitAcao(editAcao ? ActionType.Edit : ActionType.Create)}>
                  {editAcao ? 'Editar' : 'Adicionar'}
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
          <Modal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Deseja realmente excluir esta ação?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Após a exclusão não será possível visualizá-la novamente.
              </Typography>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonStato onClick={() => setOpenDeleteModal(false)} color="primary">
                  Cancelar
                </ButtonStato>
                <ButtonStato onClick={deleteAcao} variant="contained" color="success">
                  Excluir
                </ButtonStato>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Grid>
    </>
  );
};

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  position: fixed;
  bottom: 5%;
  right: 4%;
  z-index: 50;
`;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px'
};

export default HistoricoComponent;
