import { Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Pagina404 from "./pages/Pagina404";
import Home from "./pages/Home";
import MenuBar from './components/Menu';
import MiniBio from "./pages/CRM/MiniBio";
import MesaMercado from "./pages/MesaMercado";
import LogStatoGo from "./pages/LogStatoGo";
import LogSistema from "./pages/LogSistema";
import Areas from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/Areas';
import ConfigSubArea from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/ConfigSubArea";
import Segmento from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/Segmentos";
import Setor from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/Setores";
import NivelDeCargo from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/NivelDeCargo";
import FaixaSalarial from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/FaixasSalariais";
import Idioma from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/Idiomas";
import TipoDeAcao from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/TiposDeAcoes";
import DisponibilidadeDeMudança from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/DisponibilidadeDeMudanças";
import Plano from "./pages/FerramentasAdministrativas/Programas/Planos";
import Programa from "./pages/FerramentasAdministrativas/Programas/Programa";
import Usuario from "./pages/FerramentasAdministrativas/CadastroUsuarios/Usuarios";
import Grupos from './pages/FerramentasAdministrativas/CadastroUsuarios/Grupos';
import CRM from "./pages/CRM";
import Comanda from "./pages/CRM/Comanda";
import Etapa from './pages/FerramentasAdministrativas/Programas/Etapas';
import Contato from './pages/CRM/Contato';
import Status from "./pages/FerramentasAdministrativas/Programas/Status";
import Metodologias from "./pages/FerramentasAdministrativas/Programas/Metodologias";
import Prazos from "./pages/FerramentasAdministrativas/Programas/Prazos";
import PlanosTemplate from "./pages/FerramentasAdministrativas/Programas/PlanosTemplates";
import Relatorios from './pages/Relatorios';
import RelatorioTimeSheet from './pages/Relatorios/TimeSheet';
import Secoes from "./pages/Conteudo/Secoes";
import TbAreas from "./pages/Conteudo/TbAreas";
import Eventos from "./pages/Conteudo/Eventos";
import Conteudo from "./pages/Conteudo/Conteudo";
import Consultor from './pages/FerramentasAdministrativas/CadastroUsuarios/Consultores';
import TipoDeEvento from "./pages/FerramentasAdministrativas/ConfiguracaoSistemas/TiposDeEvento";
import Empresa from "./pages/CRM/Empresa";
import NovaEmpresa from "./pages/CRM/Empresa/NovaEmpresa";
import DetalhesEmpresa from "./pages/CRM/Empresa/DetalhesEmpresa";

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <MenuBar>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/conteudo" component={Conteudo} />
          <Route exact path="/mesa-mercado" component={MesaMercado} />
          <Route exact path="/log-sistema" component={LogSistema} />
          <Route exact path="/log-statoGo" component={LogStatoGo} />

          {/* Ferramentas Administrativas */}
          <Route exact path="/configuracoes-sistema/areas" component={Areas} />
          <Route exact path="/configuracoes-sistema/config-sub-areas" component={ConfigSubArea} />
          <Route exact path="/configuracoes-sistema/segmentos" component={Segmento} />
          <Route exact path="/configuracoes-sistema/setores" component={Setor} />
          <Route exact path="/configuracoes-sistema/niveis-de-cargos" component={NivelDeCargo} />
          <Route exact path="/configuracoes-sistema/faixas-salariais" component={FaixaSalarial} />
          <Route exact path="/configuracoes-sistema/idiomas" component={Idioma} />
          <Route exact path="/configuracoes-sistema/tipo-de-acao" component={TipoDeAcao} />
          <Route exact path="/configuracoes-sistema/tipo-de-Evento" component={TipoDeEvento} />
          <Route exact path="/configuracoes-sistema/disponibilidade-de-mudanca" component={DisponibilidadeDeMudança} />

          <Route exact path="/conteudo/areas" component={TbAreas} />
          <Route exact path="/conteudo/secoes" component={Secoes} />
          <Route exact path="/conteudo/conteudo" component={Conteudo} />
          <Route exact path="/conteudo/eventos" component={Eventos} />

          <Route exact path="/crm/contatos" component={Contato} />
          <Route exact path="/crm/empresa" component={Empresa} />
          <Route exact path="/crm/empresa/nova" component={NovaEmpresa} />
          <Route exact path="/crm/empresa/:id" component={DetalhesEmpresa} />
          <Route exact path="/crm/programas/novo" component={Comanda} />
          <Route exact path="/crm/contatos/:id" component={MiniBio} />

          <Route exact path="/programas/planos" component={PlanosTemplate} />
          <Route exact path="/programas/etapas" component={Etapa} />
          <Route exact path="/programas/metodologias" component={Metodologias} />
          <Route exact path="/programas/prazos" component={Prazos} />
          <Route exact path="/programas/status" component={Status} />
          <Route exact path="/programas/planos-templetes" component={PlanosTemplate} />
          <Route exact path="/programas/programas" component={Programa} />

          <Route exact path="/usuarios/usuarios" component={Usuario} />
          <Route exact path="/usuarios/consultores" component={Consultor} />
          <Route exact path="/usuarios/grupos" component={Grupos} />


          <Route exact path="/relatorios" component={Relatorios} />
          <Route exact path="/relatorios/timesheet" component={RelatorioTimeSheet} />
        </Switch>
      </MenuBar>
      <Route exact component={Pagina404} />
    </Switch>
  );
}

export default Routes;
