import { Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Icon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { BasePage, Container } from "..";
import { IEmpresa } from "../../../../commons/genericTypes";
import { ButtonStato, GridTopPage, IconTituloStato, TextFieldStato } from "../../../../commons/styleds";
import { useEmpresasActions } from "../../../../states/empresas/empresa.actions";
import { history } from '../../../../helpers/history';

interface INovaEmpresa {
  idAtualizar?: number;
}

const empty: IEmpresa = {
  id: 0,
  cnpj: "",
  razaosocial: "",
  nomefantasia: "",
  matriz: false,
  ativo: true
};

const NovaEmpresa: React.FC<INovaEmpresa> = ({ idAtualizar }) => {
  const methods = useForm({ defaultValues: empty });
  const [loadingForm, setLoadingForm] = useState(true);
  const empresaActions = useEmpresasActions();

  useEffect(() => {
    setLoadingForm(false);
  }, [])

  const onSubmit = async (data: any) => {
    setLoadingForm(true);

    if (data.id > 0)
      empresaActions
        .update(data.id, data)
        .then((result: IEmpresa) => {
          toast.success('Atualizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Atualizar!');
        }).finally(() => history.push('/crm/empresa'));
    else
      empresaActions
        .create(data)
        .then((result: IEmpresa) => {
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        }).finally(() => history.push('/crm/empresa'));
  };

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    idAtualizar = undefined;
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: "flex-end"
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant='h5'>Empresas</Typography>
        </Grid>

        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('/crm/empresa')}
            type='submit'
            variant='contained'
          >
            <Icon>undo</Icon>
            Voltar
          </ButtonStato>
        </Grid>

      </GridTopPage>

      <Container container sx={{ justifyContent: 'center' }}>
        <Grid item xs={12} md={8}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato
                id='formArea'
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <h3>
                  {idAtualizar
                    ? `Atualizar - ID: ${idAtualizar}`
                    : 'Novo Cadastro'}
                </h3>
                <Controller
                  control={methods.control}
                  name='cnpj'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='CNPJ'
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={methods.control}
                  name='nomefantasia'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Nome Fantasia'
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={methods.control}
                  name='razaosocial'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Razão social'
                      />
                    </FormControl>
                  )}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Controller
                    control={methods.control}
                    name='ativo'
                    render={({ field }) => (
                      <FormControlLabelStato
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label='Ativo'
                      />
                    )}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type='submit' variant='contained'>
                    Salvar
                  </ButtonStato>
                  {idAtualizar && (
                    <ButtonStato
                      color='error'
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant='contained'
                    >
                      Cancelar
                    </ButtonStato>
                  )}
                </div>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>
      </Container>
    </BasePage>
  )
}

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

export const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default NovaEmpresa;

