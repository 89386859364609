import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Grid,
  Checkbox,
  Typography,
  Divider,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldStato, SelectStato } from '../../../../../commons/styleds';
import {
  ModeloComercial,
  NivelHierarquico,
  TipoConsultor,
} from '../../../../../commons/enums';
import React from 'react';
import { styled } from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { win32 } from 'path';

const ConsultorStepper2: React.FC = () => {
  const methods = useFormContext();
  const grupoId = methods.watch('idGrupo');
  const tipoConsultorB2b = methods.watch('tipoConsultorB2B');
  const tipoConsultorB2c = methods.watch('tipoConsultorB2C');
  const consultorTipos = methods.watch('consultorTipos');

  const onChangeConsultorTipos = (checked: string, tipo: string) => {
    if (checked === 'false')
      methods.setValue('consultorTipos', [...consultorTipos, { tipo: tipo }]);
    else
      methods.setValue('consultorTipos', [...consultorTipos.filter((c: any) => c.tipo !== tipo)])
  };

  const onChangeValor = (tipo: string, modeloComercial: ModeloComercial, valor: number) => {
    const consultorTipo = consultorTipos.find((c: any) => c.tipo === tipo);
    if (!consultorTipo) return;

    if (modeloComercial === ModeloComercial.B2C)
      consultorTipo.valorHoraB2C = valor;
    else if (modeloComercial === ModeloComercial.B2B)
      consultorTipo.valorHoraB2B = valor;

    const novosTipos = [...consultorTipos.filter((c: any) => c.tipo !== tipo), consultorTipo];
    methods.setValue('consultorTipos', novosTipos);
  }

  return (
    <>
      <Controller
        control={methods.control}
        name='celular'
        render={({ field: { ref, ...field } }) => (
          <FormControl
            id='celular'
            fullWidth
            variant='outlined'
            sx={{ marginTop: '20px' }}
          >
            <TextFieldStato
              {...field}
              type='tel'
              inputRef={ref}
              value={field.value}
              id='celular'
              label='Celular'
            />
          </FormControl>
        )}
      />

      <GridItemCadastro item xs={12} md={12}>
        <Controller
          control={methods.control}
          name='nivelHierarquico'
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginTop: '20px' }}>
              <InputLabel id='demo-simple-select-label'>
                Nível Hierarquico
              </InputLabel>
              <SelectStato {...field} label='Nível Hierarquico'>
                {Object.keys(NivelHierarquico).map(
                  (nivel: string, index: number) => (
                    <MenuItem value={nivel} key={index}>
                      {NivelHierarquico[nivel as keyof typeof NivelHierarquico]}
                    </MenuItem>
                  )
                )}
              </SelectStato>
            </FormControl>
          )}
        />
      </GridItemCadastro>

      <GridItemCadastro container item xs={12} md={12} rowGap={2}>
        <GridItem item xs={4}>
        </GridItem>
        <Grid item xs={0.1}><Divider orientation="vertical" /></Grid>
        <GridItem item xs={7.8}>
          <Typography variant='body1'>Modelo comercial:</Typography>
        </GridItem>

        <Grid item xs={4} style={{ alignContent: 'flex-end' }}>
          <Typography variant='body1'>Tipos:</Typography>
        </Grid>
        <Grid item xs={0.1}><Divider orientation="vertical" /></Grid>

        <GridItem item xs={3.9}>
          <Controller
            control={methods.control}
            name='tipoConsultorB2C'
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'flex-end',
                }}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='B2C'
              />
            )}
          />
        </GridItem>
        <GridItem item xs={3.9}>
          <Controller
            control={methods.control}
            name='tipoConsultorB2B'
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'flex-end',
                }}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='B2B'
              />
            )}
          />
        </GridItem>

        {Object.keys(TipoConsultor).map((tipo: string) => (
          <>
            <Grid item xs={4.1}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={
                      consultorTipos?.find((c: any) => c.tipo === tipo) !==
                      undefined
                    }
                    checked={
                      consultorTipos?.find((c: any) => c.tipo === tipo) !==
                      undefined
                    }
                    onChange={(e) =>
                      onChangeConsultorTipos(e.target.value, tipo)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={tipo}
                style={{ fontSize: '16px !important', paddingRight: 0 }}
              />
            </Grid>

            <GridItem xs={3.9}>
              {tipoConsultorB2c && consultorTipos?.find((c: any) => c.tipo === tipo) !== undefined && (
                <FormControl required fullWidth variant='outlined'>
                  <NumericFormat
                    value={consultorTipos?.find((c: any) => c.tipo === tipo)?.valorHoraB2C || 0}
                    onValueChange={(e) => onChangeValor(tipo, ModeloComercial.B2C, Number(e.value))}
                    prefix='R$'
                    thousandSeparator='.'
                    decimalSeparator=','
                    label='Valor B2C'
                    customInput={TextFieldStato}
                    valueIsNumericString
                    allowLeadingZeros={false}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </FormControl>
              )}
            </GridItem>
            <GridItem xs={3.9}>
              {tipoConsultorB2b && consultorTipos?.find((c: any) => c.tipo === tipo) !== undefined && (
                <FormControl required fullWidth variant='outlined'>
                  <NumericFormat
                    value={consultorTipos?.find((c: any) => c.tipo === tipo)?.valorHoraB2B || 0}
                    onValueChange={(e) => onChangeValor(tipo, ModeloComercial.B2B, Number(e.value))}
                    prefix='R$'
                    thousandSeparator='.'
                    decimalSeparator=','
                    label='Valor B2B'
                    customInput={TextFieldStato}
                    valueIsNumericString
                    allowLeadingZeros={false}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </FormControl>
              )}
            </GridItem>
          </>
        ))}
      </GridItemCadastro>
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export default ConsultorStepper2;
