import { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { GridContainerCenter, GridItem } from '../../commons/styleds';
import Link from '@mui/material/Link';
import { history } from '../../helpers/history';
import ListItem from '@mui/material/ListItem/ListItem';
import Grid from '@mui/material/Grid';

interface subMenu {
  nome: string;
  url: string;
  cor?: string;
}

interface menu extends subMenu {
  subMenu: subMenu[]
}

const main: menu[] = [
  {
    nome: 'Configuração Sistema',
    url: '/configuracoes-sistema',
    cor: 'grey',
    subMenu: [
      {
        nome: 'Áreas',
        url: 'areas',
      },
      {
        nome: 'SubÁreas',
        url: 'config-sub-areas',
      },
      {
        nome: 'Segmentos',
        url: 'segmentos',
      },
      {
        nome: 'Setores',
        url: 'setores',
      },
      {
        nome: 'Níveis de Cargos',
        url: 'niveis-de-cargos',
      },
      {
        nome: 'Faixas Salariais',
        url: 'faixas-salariais',
      },
      {
        nome: 'Idiomas',
        url: 'idiomas',
      },
      {
        nome: 'Tipo de Ação',
        url: 'tipo-de-acao'
      },
      {
        nome: 'Tipo de Evento',
        url: 'tipo-de-evento'
      }
    ],
  },
  {
    nome: 'Programas',
    url: '/programas',
    cor: 'var(--blue3)',
    subMenu: [
      {
        nome: 'Etapas',
        url: 'etapas',
      },
      {
        nome: 'Metodologias',
        url: 'metodologias',
      },
      {
        nome: 'Status',
        url: 'status',
      },
      {
        nome: 'Template de Planos',
        url: 'planos',
      }
    ],
  },
  {
    nome: 'Cadastro de Usuário',
    url: '/usuarios',
    cor: 'var(--green3)',
    subMenu: [
      {
        nome: 'Usuários',
        url: 'usuarios',
      },
      {
        nome: 'Grupos (Departamentos)',
        url: 'grupos',
      },
      {
        nome: 'Consultores',
        url: 'consultores',
      },
    ],
  }
];

const Menu: React.FC = () => {
  const [subMenu, setSubMenu] = useState<subMenu[] | undefined>();
  const path = window.location.pathname;

  useEffect(() => {
    const submenu = main.find((menu) => path.includes(menu.url))?.subMenu;

    setSubMenu(submenu);
  }, []);

  return (
    <Grid item sm={12}>
      <Container container>
        {main.map((menuItem, index) => (
          <GridItem item sm={12} md={6} lg={3}>
            <ListItem
              key={menuItem.nome}
              disablePadding
              sx={{ display: 'block' }}
            >
              <CardMenu selecionado={path.includes(menuItem.url).toString()}>
                <ItemText
                  onClick={() =>
                    history.push(menuItem.url + '/' + (menuItem.subMenu.length > 0 ? menuItem.subMenu[0]?.url : ''))
                  }
                >
                  {menuItem.nome}
                </ItemText>
              </CardMenu>
            </ListItem>
          </GridItem>
        ))}
      </Container>

      {subMenu && (
        <ContainerSubMenu container spacing={3}>
          {subMenu.map((menuItem, index) => (
            <GridItemSubMenu item>
              <ListItem
                key={menuItem.nome}
                disablePadding
                sx={{ display: 'block', cursor: "pointer" }}
              >
                <ItemSubMenu
                  selected={path.split('/').pop() === menuItem.url}
                  onClick={() => history.push(menuItem.url)}
                >
                  {menuItem.nome}
                </ItemSubMenu>
              </ListItem>
            </GridItemSubMenu>
          ))}
        </ContainerSubMenu>
      )}
    </Grid>
  );
};

const Container = styled(GridContainerCenter)`
  justify-content: space-around;
  padding-top: 20px;
  padding-left: 20px;
  @media (max-width: 700px) {
    justify-content: flex-start;
  }
`;

const ItemText = styled(Link)`
  font-weight: 600 !important;
  font-size: 14px;
  color: black !important;
  text-decoration: none !important;
  cursor: pointer;
`;

const ContainerSubMenu = styled(Container)`
  margin-top: 30px !important;
  justify-content: flex-start !important;
`;

const GridItemSubMenu = styled(GridItem)`
  margin-top: 30px !important;
  padding-top: 0px !important;
`;

const ItemSubMenu = styled(ItemText) <{ selected: boolean }>`
  cursor: "pointer";
  margin-top: 25px;
  text-wrap: nowrap;
  ${(p) => (p.selected ? 'text-decoration: underline !important;' : '')}
`;

const CardMenu = styled.div<{ selecionado: any }>`
  opacity: ${(p) => (p.selecionado === 'true' ? 0.8 : 1)};
  margin-top: 10px;
  display: inline-block;
  width: 170px;
  height: 38px;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 6px 1px 1px 6px;
  border: 1px solid black;
  border-right: 0;
  position: relative;
  color: black;
  line-height: 38px;
  padding: 0 10px 0 10px;

  background: linear-gradient(
    0,
    ${(p) => (p.selecionado === 'true' ? 'var(--green3)' : 'var(--blue3)')} 20%,
    var(--grayBackground) 33%,
    transparent 35%
  );

  /* Makes the triangle */
  &:before {
    content: '';
    position: absolute;
    width: 28px;
    height: 27px;
    right: -14px;
    top: 5px;
    border: 1px solid black;
    border-radius: 2px;
    border-left: 0;
    border-top: 0;
    transform: rotate(315deg);

    background: linear-gradient(
      45deg,
      ${(p) => (p.selecionado === 'true' ? 'var(--green3)' : 'var(--blue3)')}
        20%,
      var(--grayBackground) 33%,
      transparent 35%
    );
  }
`;

export default Menu;
