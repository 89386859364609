import { FormControl, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

interface Props {
  novoUsuario: boolean;
}

const PPCStepper6: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  return (
    <>
      <Grid container xs={12} md={12} sm={12} sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}>
        <Grid xs={12} md={12} sm={12}>
          <Typography sx={{ padding: "10px 0px" }} variant="h5" component="h2">
            Estilo de Liderança
          </Typography>
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal"
          }}>
            Discorra sobre seu estilo de liderança e fale de um case de sucesso e insucesso. Se nunca liderou pessoas, fale sobre seu modelo ideal de gestor.
          </InputLabel >
          <Controller
            control={methods.control}
            name='estiloDeLiderancaP1'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal"
          }}>
            Resuma seus pontos fortes, levando em consideração as reflexões feitas na matriz SWOT Pessoal?
          </InputLabel >
          <Controller
            control={methods.control}
            name='estiloDeLiderancaP2'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}

                variant="standard"
                disabled
                multiline
                maxRows={4}
              />
            )}
          />
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <InputLabel sx={{
            whiteSpace: "normal"
          }}>
            O que você identifica como oportunidade de melhoria no seu perfil?
          </InputLabel >
          <Controller
            control={methods.control}
            name='estiloDeLiderancaP3'
            render={({ field: { ref, ...field } }) => (
              <TextField sx={{ width: "100%" }}
                id="standard-basic"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="standard"
                disabled
                multiline
                maxRows={4}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PPCStepper6;
