import { disponibilidadeDeMudançaAtom } from './DisponibilidadeDeMudançaState';
import { useBaseActions } from '../../../base.actions';

function useDisponibilidadeDeMudançaActions() {
  const baseActions = useBaseActions('configDisponibilidadeMudanca', disponibilidadeDeMudançaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useDisponibilidadeDeMudançaActions };
