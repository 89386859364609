import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';
import { competenciaAtom } from './CompetenciaState';

function useCompetenciaActions() {
  const baseActions = useBaseActions('configCompetencias', competenciaAtom);
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };

}

export { useCompetenciaActions };
