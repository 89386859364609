import { faixaSalarialAtom } from './FaixaSalarialState';
import { useBaseActions } from '../../../base.actions';

function useFaixaSalarialActions() {
  const baseActions = useBaseActions('ConfigFaixasSalariais', faixaSalarialAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useFaixaSalarialActions };
