import styled from "styled-components";
import { ButtonStato } from "../../../commons/styleds";
import { GridItemCadastro } from "../Comanda";

interface IButtonAdd {
  onClick: Function;
}

const ButtonAdd: React.FC<IButtonAdd> = ({ onClick }) => {
  return (
    <GridItemCadastroBtnSalvar
      item
      xs={11}
      md={11}
    >
      <ButtonStato
        style={{
          padding: '0px',
          height: '35px',
          minWidth: '35px',
        }}
        onClick={() => onClick(true)}
        variant='contained'
      >
        +
      </ButtonStato>
    </GridItemCadastroBtnSalvar>
  );
};

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  position: absolute;
  z-index: 1;
  right: 20px;
  bottom: 90px;
`;

export default ButtonAdd;

