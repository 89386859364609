import { statusAtom } from '../status/StatusState';
import { useBaseActions } from '../../../base.actions';

function useStatusActions() {
  const baseActions = useBaseActions('status', statusAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useStatusActions };
