import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Drawer,
  Typography,
  Icon,
  LinearProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { ITelefone } from '../../../../commons/genericTypes';
import {
  ButtonStato,
  IconDrawer,
  TextFieldStato,
} from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { useParams } from 'react-router-dom';
import { useTelefoneActions } from '../../../../states/crm/contatoAuxiliares/telefones/telefone.actions';
import { GridItemCadastroBtnSalvar } from '..';
import { possuiPermissao } from '../../../../commons/validaPermissao';
import ButtonAdd from '../ButtonAdd';
import EditIcon from '@mui/icons-material/Edit';

const empty: ITelefone = {
  id: 0,
  idContato: 0,
  idTipo: 3,
  nome: '',
  ddd: '',
  telefone: '',
  ramal: '',
  principal: false,
  ativo: true,
};

interface IParams {
  id?: string;
}

const TelefoneComponent: React.FC = () => {
  const { id } = useParams<IParams>();
  const auth = useRecoilValue(authAtom);
  const telefoneActions = useTelefoneActions();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [telefones, setTelefones] = useState<ITelefone[]>([]);
  const [telefone, setTelefone] = useState<ITelefone>(empty);

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 190,
    },
    {
      field: 'ddd',
      headerName: 'DDD',
      width: 150,
    },
    {
      field: 'telefone',
      headerName: 'Número',
      width: 120,
    },
    {
      field: 'ramal',
      headerName: 'Ramal',
      width: 150,
    },
    {
      field: 'principal',
      headerName: 'Principal',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ITelefone;
        return (
          <Checkbox
            checked={obj.principal}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      },
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ITelefone;
        return (
          <>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleEditar(telefones.find(f => f.id === obj.id!)!)}
            >
              {' '}
              <EditIcon />{' '}
            </Icon>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleApagar(obj.id!)}
            >
              {' '}
              <DeleteIcon />{' '}
            </Icon>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    telefoneActions
      .list(false, `?contatoId=${id}`)
      .then((data) => setTelefones(data));

    setLoading(false);
  }, []);

  const openDrawerTelefone = (result: any) => {
    setOpen(result);
  };

  const handleEditar = (item: ITelefone) => {
    setTelefone(item);
    openDrawerTelefone(true);
  };

  const handleApagar = (id: any) => {
    telefoneActions.delete(id).then(() => {
      const sub = telefones.filter((f) => f.id !== id);
      setTelefones(sub);
      toast.success('Apagado com sucesso!');
    });
  };

  const AdicionarNaLista = async () => {
    setLoadingSave(true);

    try {
      telefone.idTipo = 2;

      if ((!telefone?.idContato || telefone?.idContato <= 0) && Number(id) > 0) {
        telefone.idContato = Number(id);
      }

      let response: ITelefone;
      if (telefone.id && telefone.id > 0) {
        response = await telefoneActions.update(telefone.id, telefone);
        setTelefones((prevTelefones) => {
          const newList = prevTelefones.filter((f) => f.id !== response.id);
          return newList.concat(response);
        });

        toast.success('Telefone atualizado');
      } else {
        response = await telefoneActions.create(telefone);
        setTelefones((prevTelefones) => prevTelefones.concat(response));

        toast.success('Telefone salvo');
      }

      setTelefone(emptyTelefone());
      openDrawerTelefone(false);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao salvar telefone');
    } finally {
      setLoadingSave(false);
    }
  };

  const emptyTelefone = () => {
    return {
      id: 0,
      idContato: Number(id),
      idTipo: 3,
      nome: '',
      ddd: '',
      telefone: '',
      ramal: '',
      principal: false,
      ativo: true,
    }
  }

  const handleTelefone = (event: any) => {
    if (event.target.value.length > 10) return '';
    event.target.value = event.target.value.replace(/\D/g, '');
    event.target.value = event.target.value.replace(/(\d)(\d{4})$/, '$1-$2');
    setTelefone({ ...telefone, telefone: event.target.value });
  };

  const handleDDD = (event: any) => {
    if (event.target.value.length > 5) return '';
    event.target.value = event.target.value.replace(/\D/g, '');
    event.target.value = event.target.value.replace(/(\d{2})(\d)/, '($1)');
    setTelefone({ ...telefone, ddd: event.target.value });
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id}_${row?.nome}`}
              rows={telefones ?? []}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <ButtonAdd onClick={() => openDrawerTelefone(true)} />

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerTelefone(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerTelefone(false)}>
                    close
                  </IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant='h5'>
                    {'Adicionar Telefone na Lista'}
                  </Typography>
                </Grid>
              </Grid>

              <FormControl
                id='cep'
                required
                fullWidth
                variant='outlined'
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato
                  type='text'
                  label='Nome'
                  value={telefone?.nome}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTelefone({ ...telefone, nome: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl
                required
                fullWidth
                variant='outlined'
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato
                  id={'TelefoneInput'}
                  type='text'
                  label='DDD'
                  value={telefone?.ddd}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleDDD(event);
                  }}
                />
              </FormControl>

              <FormControl
                required
                fullWidth
                variant='outlined'
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato
                  id={'TelefoneInput'}
                  type='numero'
                  label='Número'
                  value={telefone?.telefone}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTelefone(event);
                  }}
                />
              </FormControl>

              <FormControl
                required
                fullWidth
                variant='outlined'
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato
                  id={'TelefoneInput'}
                  type='text'
                  label='Ramal'
                  value={telefone?.ramal}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTelefone({ ...telefone, ramal: event.target.value });
                  }}
                />
              </FormControl>

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Principal"
                control={
                  <Checkbox onClick={() => setTelefone({ ...telefone, principal: !telefone.principal })} />
                }
              />

              {loadingSave && <LinearProgress />}

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant='contained' onClick={AdicionarNaLista}>
                  Adicionar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
};


export default TelefoneComponent;
