import { Grid, Typography } from '@mui/material';
import {
  GridTopPage,
  IconArrowBackStato,
  IconTituloStato,
} from '../../commons/styleds';
import { history } from '../../helpers/history';
import styled from 'styled-components';

interface IHeaderPages {
  arrowBackClick: string;
  icon: string;
  title: string;
  actionBack: boolean;
}

const HeaderPages: React.FC<IHeaderPages> = (props) => {
  const { arrowBackClick, icon, title, actionBack } = props;

  return (
    <GridTopPage item xs={12} md={12}>
      <GridHeader item xs={12} md={12}>
        {actionBack &&
          <IconArrowBackStato onClick={() => history.push(arrowBackClick)}>
            arrow_back
          </IconArrowBackStato>
        }
        <IconTituloStato>{icon}</IconTituloStato>
        <Typography variant='h5'>{title}</Typography>
      </GridHeader>
    </GridTopPage>
  );
};

const GridHeader = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export default HeaderPages;
