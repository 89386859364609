import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';
import { perfilAtom } from './PerfilState';

function usePerfilActions() {
  const baseActions = useBaseActions('perfis', perfilAtom);
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };

}

export { usePerfilActions };
