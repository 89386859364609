import React from 'react';
import { useEffect, useState } from 'react';
import {
  GridTopPage,
  IconTituloStato,
  ButtonStato,
} from '../../../commons/styleds';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { IContato, ICRM } from '../../../commons/genericTypes';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  ptBR,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import { history } from '../../../helpers/history';
import { useContatoActions } from '../../../states/crm/contatos/contato.actions';

const Contatos: React.FC = () => {
  const contatoActions = useContatoActions();
  const [contatos, setContatos] = useState<IContato[]>([]);
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;

  const buscaInicial = async () => {
    const searchParams = new URLSearchParams(search);
    const textoBusca = searchParams.get('busca');
    try {
      const resp: IContato[] = await contatoActions.list(textoBusca);
      setContatos(resp);
    } catch (error: any) {
      toast.warn(error);
    }
  }

  useEffect(() => {
    setLoading(true);
    buscaInicial().finally(() => setLoading(false));
  }, [search]);

  const deletarCRM = async (data: ICRM) => {
    setLoading(true);
    try {
      await contatoActions.delete(data.id ?? 0)
      toast.success('Contato deletado');
      await buscaInicial();
    } catch (error) {
      console.error(error);
      toast.error('Erro ao deletar contato!');
    }
    setLoading(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'empresa',
      headerName: 'Empresa',
      maxWidth: 195,
      flex: 0,
    },
    {
      field: 'nome',
      headerName: 'Nome',
      maxWidth: 165,
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 130,
    },
    {
      field: 'email',
      headerName: 'E-mail Principal',
      width: 180,
    },
    {
      field: 'depto',
      headerName: 'Depto.',
      width: 130,
    },
    {
      field: 'nivelPosicao',
      headerName: 'Nível Posição',
      width: 130,
    },
    {
      field: 'escopoDeResponsabilidade',
      headerName: 'Escopo de Responsabilidade',
      maxWidth: 220,
      flex: 0.5,
    },
    {
      field: '',
      headerName: 'Ações',
      maxWidth: 100,
      flex: 0.5,
      editable: false,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params: GridCellParams) => {
        let crm = params.row as ICRM;
        return (
          <>
            <Link
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                paddingRight: '8px',
                color: 'rgb(70, 70, 70)',
              }}
              to={'/crm/contatos/' + crm.id}
              onClick={() => console.log(crm.id)}
            >
              <EditIcon />
            </Link>

            <Icon
              onClick={() => deletarCRM(crm)}
              style={{ color: 'black', cursor: 'pointer' }}
            >
              delete
            </Icon>
          </>
        );
      },
    },
  ];

  const handleRowClick: GridEventListener<'rowClick'> = (params: any, event: any) => {
    if (
      event.target.ariaColIndex === null ||
      Number(event.target.ariaColIndex) === columns.length
    )
      event.stopPropagation();
    else
      history.push('/crm/contatos/' + params.id);
  };

  return (
    <>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          <IconTituloStato>person</IconTituloStato>
          <Typography variant='h5'>Contatos</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('/crm/programas/novo')}
            type='submit'
            variant='contained'
          >
            <Icon>person_add</Icon>
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ alignContent: 'center', alignItems: 'center' }}
        style={{ padding: '0px 30px' }}
      >
        <Box
          sx={{
            height: '70vh',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => row?.id}
              rows={contatos}
              onRowClick={handleRowClick}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
              }}
            />
          </ThemeProvider>
        </Box>
      </Grid>
    </>
  );
};

export default withRouter(Contatos);
