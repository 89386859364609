import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { GridContainer } from "../../../../../commons/styleds";

interface PeriodoPlanoProps {
  periodoEmMeses: number;
  setPeriodoEmMeses: React.Dispatch<React.SetStateAction<number>>;
}

const PeriodoPlano: React.FC<PeriodoPlanoProps> = ({ periodoEmMeses, setPeriodoEmMeses }) => {
  const years = Math.floor(periodoEmMeses / 12);
  const months = periodoEmMeses % 12;

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newYears = parseInt(event.target.value, 10);
    if (!isNaN(newYears)) {
      setPeriodoEmMeses(newYears * 12 + months);
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMonths = parseInt(event.target.value, 10);
    if (!isNaN(newMonths) && newMonths >= 0 && newMonths < 12) {
      setPeriodoEmMeses(years * 12 + newMonths);
    }
  };

  return (
    <FormControl component="fieldset" style={{ marginBottom: '16px', marginTop: '16px' }}>
      <FormLabel component="legend" style={{ marginBottom: '8px' }}>Defina o Período</FormLabel>
      <GridContainer container style={{ marginTop: '16px', columnGap: '8px' }}>
        <Grid item xs={4}>
          <TextField
            label="Anos"
            type="number"
            value={years}
            onChange={handleYearChange}
            inputProps={{ min: 0 }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Meses"
            type="number"
            value={months}
            onChange={handleMonthChange}
            inputProps={{ min: 0, max: 11 }}
            variant="outlined"
          />
        </Grid>
        <div>Total de meses: {periodoEmMeses}</div>
      </GridContainer>
    </FormControl>
  );
};

export default PeriodoPlano;

