import { styled } from 'styled-components';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Alert,
  Drawer,
  Typography,
  Icon,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  InputLabel,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, SubmitHandler, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { IAreaPosicao, IContatoHistoricoEmpresa, IContatoHistoricoEmpresaPosicao, IEscopoResonsabilidade, IHistorico, INivelPosicao } from '../../../../commons/genericTypes';
import { ButtonStato, GridContainer, GridContainerCenter, IconDrawer, IconTituloStato, SelectStato, TextFieldStato } from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { possuiPermissao } from '../../../../commons/validaPermissao';
import { useContatoHistoricoEmpresaActions } from '../../../../states/crm/contatoAuxiliares/contatoHistoricoEmpresas/ContatoHistoricoEmpresaActions';
import { useContatoHistoricoEmpresaPosicoesActions } from '../../../../states/crm/contatoAuxiliares/ContatoHistoricoEmpresaPosicao/ContatoHistoricoEmpresaPosicoesActions';
import { useParams } from 'react-router-dom';
import { IParams } from '../Endereco';
import { useEmpresasActions } from '../../../../states/empresas/empresa.actions';
import { AccordionDetails, AccordionSummary } from '../accordionAndTabPanel';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAreaPosicaoActions } from '../../../../states/areaPosicoes/areaPosicao.actions';
import { useNivelDeCargoActions } from '../../../../states/config/ConfiguracaoSistema/niveisDeCargos/nivelDeCargo.actions';
import { useNivelPosicaoActions } from '../../../../states/crm/contatoAuxiliares/nivelPosicao/nivelPosicao.actions';
import { useEscopoResponsabilidadeActions } from '../../../../states/crm/contatoAuxiliares/escopoResponsabilidade/escopoResponsabilidade.actions';
import moment from 'moment';
import dayjs from 'dayjs';
import { filter, IEmpresaType } from '../../Comanda/components/EmpresaCliente';
import ButtonAdd from '../ButtonAdd';
import EditIcon from '@mui/icons-material/Edit';

const emptyPosicao: IContatoHistoricoEmpresaPosicao = {
  id: 0,
  historicoEmpresaId: 0,
  cargo: '',
  realizacoes: '',
  responsabilidades: '',
  motivoSaida: '',
  superior: '',
  cargoAtual: false,
  numeroDeSubordinados: 0,
  areaId: 0,
  nivelId: 0,
  escopoId: 0,
  dataInicio: null,
  dataTermino: null
}

const HistoricoComponent: React.FC = () => {
  const { id } = useParams<IParams>();
  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  const contatoHistoricoEmpresaActions = useContatoHistoricoEmpresaActions();
  const contatoHistoricoEmpresaPosicoesActions = useContatoHistoricoEmpresaPosicoesActions();
  const empresaActions = useEmpresasActions();
  const areaActions = useAreaPosicaoActions();
  const nivelActions = useNivelPosicaoActions();
  const escopoActions = useEscopoResponsabilidadeActions();

  const [contatoHistoricoEmpresas, setContatoHistoricoEmpresas] = useState<IContatoHistoricoEmpresa[]>([]);
  const [contatoHistoricoEmpresa, setContatoHistoricoEmpresa] = useState<IContatoHistoricoEmpresa>();

  const [tableData, setTableData] = useState<IHistorico[]>([]);
  const [value, setValue] = useState<IEmpresaType | null>(null);
  const [openEmpresaDialog, setOpenEmpresaDialog] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    razaosocial: '',
    cnpj: '',
  });

  const [empresas, setEmpresas] = useState<IEmpresaType[]>([]);
  const [areasPosicao, setAreasPosicao] = useState<IAreaPosicao[]>([]);
  const [niveisPosicao, setNiveisPosicao] = useState<INivelPosicao[]>([]);
  const [escoposPosicao, setEscoposPosicao] = useState<IEscopoResonsabilidade[]>([]);

  const { control, handleSubmit, register, watch, reset } = useForm<IContatoHistoricoEmpresa>({
    defaultValues: {
      posicoes: [emptyPosicao]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "posicoes"
  });
  const posicoesValue = watch("posicoes");

  const resetValues = () => {
    setContatoHistoricoEmpresa(undefined);
    setValue(null);
    reset({ posicoes: [emptyPosicao] });
  }

  const columns: GridColDef[] = [
    {
      field: 'empresa',
      headerName: 'Empresa',
      width: 190,
    },
    {
      field: 'dataInicio',
      headerName: 'Data Início',
      width: 150,
    },
    {
      field: 'dataFim',
      headerName: 'Data Fim',
      width: 120,
    },
    {
      field: 'atual',
      headerName: 'Atual',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistorico;
        return (
          <Checkbox
            checked={Boolean(obj.atual)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      },
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IHistorico;
        return (
          <>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => atualizarFormulario(obj)}
            >
              {' '}
              <EditIcon />{' '}
            </Icon>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => HandleApagar(obj.id!)}
            >
              {' '}
              <DeleteIcon />{' '}
            </Icon>
          </>
        )
      },
    },
  ];

  const onSubmit = async (data: IContatoHistoricoEmpresa) => {
    if (!value) {
      alert('É necessário selecionar uma empresa');
      return;
    }
    setLoadingSave(true);

    if (!contatoHistoricoEmpresa)
      await adicionarNovoHistorico(data);
    else
      await editarHistorico(data);

    setLoadingSave(false);
    openDrawerHistorico(false);
    getContatoHistoricoEmpresas();
  };

  const adicionarNovoHistorico = async (data: IContatoHistoricoEmpresa) => {
    const historicoEmpresa = {
      contatoId: id,
      empresaId: value?.id
    }

    const resp = await contatoHistoricoEmpresaActions.create(historicoEmpresa);
    await data.posicoes?.forEach(async (posicao: IContatoHistoricoEmpresaPosicao) => {
      const req = {
        ...posicao,
        historicoEmpresaId: resp.id,
        dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
        dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
      }
      const posicaoResp = await contatoHistoricoEmpresaPosicoesActions.create(req);
      resp.posicoes.push(posicaoResp);
    });
    toast.success('Histórico cadastrado');
  }

  const editarHistorico = async (data: IContatoHistoricoEmpresa) => {
    const posicoesNovas: IContatoHistoricoEmpresaPosicao[] = [];
    const posicoesAlteradas: IContatoHistoricoEmpresaPosicao[] = [];
    const posicoesRemovidas: IContatoHistoricoEmpresaPosicao[] = [];

    data.posicoes?.forEach(posicao => {
      const req = {
        ...posicao,
        historicoEmpresaId: contatoHistoricoEmpresa!.id,
        dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
        dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
      } as any;
      if (posicao.id === 0)
        posicoesNovas.push(req);
      else if (contatoHistoricoEmpresa?.posicoes?.map(p => p.id)?.includes(posicao?.id))
        posicoesAlteradas.push(req);
      else
        posicoesRemovidas.push(posicao);
    });
    const newRequests = posicoesNovas.map(posicao => contatoHistoricoEmpresaPosicoesActions.create(posicao));

    const updateRequests = posicoesAlteradas.map(posicao => contatoHistoricoEmpresaPosicoesActions.update(posicao.id!, posicao));
    const deleteRequests = posicoesRemovidas.map(posicao => contatoHistoricoEmpresaPosicoesActions.delete(posicao.id!));

    await Promise.all([...newRequests, ...updateRequests, ...deleteRequests]);

    toast.success('Historico alterado');
  }

  const removerPosicao = (index: number) => {
    remove(index);
  }

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    getContatoHistoricoEmpresas();
    getEmpresas();
    getAreas();
    getNiveis();
    getEscopos();

    setLoading(false);
  }, []);

  const getContatoHistoricoEmpresas = () => {
    contatoHistoricoEmpresaActions
      .list(false, `?contatoId=${id}`)
      .then((data: IContatoHistoricoEmpresa[]) => {
        setContatoHistoricoEmpresas(data);

        const dataTable = data.map((c: IContatoHistoricoEmpresa) => {
          const posicoesSorted = c.posicoes!.length > 0
            ? c.posicoes?.sort((a, b) => new Date(a.dataInicio as any).getTime() - new Date(b.dataInicio as any).getTime())
            : [];
          return {
            id: c.id,
            empresa: c.empresaIdNavigation?.razaosocial,
            dataInicio: posicoesSorted?.length ? formatDate(posicoesSorted[0]?.dataInicio) : null,
            dataFim: posicoesSorted?.length ? formatDate(posicoesSorted[posicoesSorted.length - 1]?.dataTermino) : null,
            atual: posicoesSorted?.length ? posicoesSorted[posicoesSorted.length - 1].cargoAtual.toString() : 'false',
          } as IHistorico;
        });

        setTableData(dataTable);
      })
      .catch((error: any) => toast.error(error));
  }

  const formatDate = (date: Date | any) => {
    return moment(date).format('DD/MM/YYYY');
  }

  const getEmpresas = () => {
    empresaActions
      .list()
      .then((resp: any) => {
        setEmpresas(resp);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Falha ao buscar empresas');
      });
  }

  const getAreas = () => {
    areaActions
      .list()
      .then((resp: any) => {
        setAreasPosicao(resp);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Falha ao buscar Areas Posição');
      });
  }

  const getNiveis = () => {
    nivelActions
      .list()
      .then((resp: any) => {
        setNiveisPosicao(resp);
      })
      .catch((e) => {
        toast.error('Falha ao buscar Níveis Posição');
      });
  }

  const getEscopos = () => {
    escopoActions
      .list()
      .then((resp: any) => {
        setEscoposPosicao(resp);
      })
      .catch((e) => {
        toast.error('Falha ao buscar Escopos de Responsabilidades');
      });
  }

  const atualizarFormulario = (result: any) => {
    setLoadingForm(true);

    const contHist = contatoHistoricoEmpresas.find(c => c.id === result.id);
    if (contHist === undefined) return;

    setContatoHistoricoEmpresa(contHist);
    setValue(empresas.find(e => e.id === contHist!.empresaId)!);

    const posicoesMapped = contHist.posicoes!.map((element: IContatoHistoricoEmpresaPosicao) => ({
      ...element,
      dataInicio: dayjs(element.dataInicio?.toString()),
      dataTermino: dayjs(element.dataTermino?.toString())
    }));
    reset({ posicoes: posicoesMapped });
    openDrawerHistorico(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const openDrawerHistorico = (result: any) => {
    setOpen(result);
    if (!result)
      resetValues();
  };

  const HandleApagar = (id: any) => {
    setLoading(true);
    contatoHistoricoEmpresaActions
      .delete(id)
      .then(() => {
        const sub = tableData.filter((f) => f.id !== id);
        setTableData(sub);
        toast.success('Histórico removido com sucesso')
      })
      .catch((e) => toast.error('Falha ao remover histórico'))
      .finally(() => setLoading(false));
  };

  const AdicionarNaLista = () => {
    openDrawerHistorico(false);
  };

  const handleClose = () => {
    setDialogValue({
      razaosocial: '',
      cnpj: '',
    });
    setOpenEmpresaDialog(false);
  };

  const handleSubmitEmpresa = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!dialogValue.razaosocial || !dialogValue.cnpj) {
      toast.warn('Preencha todos os campos necessários');
      return;
    }
    const valueSubmit = {
      razaosocial: dialogValue.razaosocial,
      cnpj: dialogValue.cnpj,
    };
    empresaActions
      .create(valueSubmit)
      .then((data) => {
        setValue(data);
        toast.success('Empresa criada com sucesso');
      })
      .catch((e) => {
        console.error(e);
        toast.error('Falha ao salvar nova Empresa');
      });

    handleClose();
  };

  return <>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          height: '300px',
          width: '100%',
        }}
      >
        <ThemeProvider theme={createTheme({}, ptBR)}>
          <DataGrid
            getRowId={(row) => row?.id}
            rows={tableData}
            columns={columns}
            loading={loading}
            sx={{
              backgroundColor: 'white',
              border: '1px solid black',
              borderRadius: '14px',
            }}
          />
        </ThemeProvider>

        <ButtonAdd onClick={() => openDrawerHistorico(true)} />

        <Drawer
          style={{ minWidth: '450px !important' }}
          anchor={'right'}
          open={open}
          onClose={() => openDrawerHistorico(false)}
        >
          <Grid item xs={12} md={12} style={{ padding: "90px 30px", maxWidth: "500px", width: "auto" }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '450px' }}>

              <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: "center" }}>
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerHistorico(false)}>close</IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant="h5">Histórico Profissional</Typography>
                </Grid>
              </Grid>

              <GridItemCadastro item xs={12} style={{ marginTop: '30px' }}>
                <>
                  <Autocomplete
                    value={value}
                    disabled={(contatoHistoricoEmpresa?.id ?? 0) > 0}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setTimeout(() => {
                          setOpenEmpresaDialog(true);
                          setDialogValue({
                            razaosocial: newValue,
                            cnpj: '',
                          });
                        });
                      } else if (newValue && newValue.inputValue) {
                        setOpenEmpresaDialog(true);
                        setDialogValue({
                          razaosocial: newValue.inputValue,
                          cnpj: '',
                        });
                      } else {
                        setValue(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          razaosocial: `Adicionar: "${params.inputValue}"`,
                          cnpj: '',
                        });
                      }

                      return filtered;
                    }}
                    id='adicionar-empresa-dialog'
                    options={empresas}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.razaosocial;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option) => (
                      <li {...props}>{option.razaosocial}</li>
                    )}
                    fullWidth
                    freeSolo
                    renderInput={(params) => (
                      <TextFieldStato {...params} label='Empresa Cliente' />
                    )}
                  />
                  <Dialog open={openEmpresaDialog} onClose={handleClose}>
                    <form>
                      <DialogTitle>Adicionar nova Empresa</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Complete as informações da nova Empresa!
                        </DialogContentText>
                        <TextFieldStato
                          autoFocus
                          margin='dense'
                          id='razaosocial'
                          value={dialogValue.razaosocial}
                          onChange={(event) =>
                            setDialogValue({
                              ...dialogValue,
                              razaosocial: event.target.value,
                            })
                          }
                          label='Razão Social'
                          type='text'
                        />
                        <TextFieldStato
                          margin='dense'
                          id='cnpj'
                          value={dialogValue.cnpj}
                          onChange={(event) =>
                            setDialogValue({
                              ...dialogValue,
                              cnpj: event.target.value,
                            })
                          }
                          label='CNPJ'
                          type='text'
                        />
                      </DialogContent>
                      <DialogActions>
                        <ButtonStato color='error' onClick={handleClose}>
                          Cancelar
                        </ButtonStato>
                        <ButtonStato onClick={(e: any) => handleSubmitEmpresa(e)}>
                          Adicionar
                        </ButtonStato>
                      </DialogActions>
                    </form>
                  </Dialog>
                </>
              </GridItemCadastro>

              {fields.map((field, index) => (
                <>
                  <Accordion defaultExpanded key={index}>
                    <AccordionSummary
                      aria-controls='panel1d-content'
                      id='panel1d-header'
                    >
                      <Typography>Cargo - {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Controller
                          name={`posicoes.${index}.areaId` as const}
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel>Área</InputLabel>
                              <SelectStato {...field} label="Área">
                                {areasPosicao.map((area: IAreaPosicao) => (
                                  <MenuItem value={area.id}>{area.nome}</MenuItem>
                                ))}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                        <Controller
                          name={`posicoes.${index}.nivelId` as const}
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel>Nível</InputLabel>
                              <SelectStato {...field} label="Nível">
                                {niveisPosicao.map((nivel: INivelPosicao) => (
                                  <MenuItem value={nivel.id}>{nivel.nome}</MenuItem>
                                ))}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                        <Controller
                          name={`posicoes.${index}.escopoId` as const}
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel>Escopo</InputLabel>
                              <SelectStato {...field} label="Escopo">
                                {escoposPosicao.map((escopo: IEscopoResonsabilidade) => (
                                  <MenuItem value={escopo.id}>{escopo.nome}</MenuItem>
                                ))}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                        <TextFieldStato
                          fullWidth
                          label="Cargo"
                          variant="outlined"
                          {...register(`posicoes.${index}.cargo` as const)}
                        />
                        <Controller
                          name={`posicoes.${index}.cargoAtual` as const}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                />
                              }
                              label="Cargo Atual"
                            />
                          )}
                        />
                        <Controller
                          name={`posicoes.${index}.dataInicio` as const}
                          control={control}
                          render={({ field }) => (
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                clearButtonLabel: 'Empty',
                                todayButtonLabel: 'Now',
                              }}
                            >
                              <DesktopDatePicker
                                {...field}
                                label='Data Início'
                                format='DD/MM/YYYY'
                              />
                            </LocalizationProvider>
                          )}
                        />
                        <Controller
                          name={`posicoes.${index}.dataTermino` as const}
                          control={control}
                          render={({ field }) => (
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                clearButtonLabel: 'Empty',
                                todayButtonLabel: 'Now',
                              }}
                            >
                              <DesktopDatePicker
                                {...field}
                                label='Data Término'
                                format='DD/MM/YYYY'
                              />
                            </LocalizationProvider>
                          )}
                        />
                        <TextFieldStato
                          fullWidth
                          label="Superior (contato de referência)"
                          variant="outlined"
                          {...register(`posicoes.${index}.superior` as const)}
                        />
                        <TextFieldStato
                          fullWidth
                          label="Número de Subordinados"
                          variant="outlined"
                          type="number"
                          {...register(`posicoes.${index}.numeroDeSubordinados` as const)}
                        />
                        <TextFieldStato
                          fullWidth
                          label="Responsabilidades"
                          variant="outlined"
                          multiline
                          minRows={3}
                          {...register(`posicoes.${index}.responsabilidades` as const)}
                        />
                        <TextFieldStato
                          fullWidth
                          label="Realizações"
                          variant="outlined"
                          multiline
                          minRows={3}
                          {...register(`posicoes.${index}.realizacoes` as const)}
                        />
                        <TextFieldStato
                          fullWidth
                          label="Motivo da Saída"
                          variant="outlined"
                          multiline
                          {...register(`posicoes.${index}.motivoSaida` as const)}
                        />
                        <ButtonStato type="button" variant="contained" color="error" onClick={() => removerPosicao(index)}>
                          Apagar
                        </ButtonStato>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}

              <ButtonStato type="button" onClick={() => append(emptyPosicao)} sx={{ mt: 2 }}>
                Adicionar Nova Posição
              </ButtonStato>
              <ButtonStato type="submit" variant="contained" sx={{ mt: 2 }}>
                Enviar
              </ButtonStato>
            </form>
          </Grid>
        </Drawer >
      </Box >
    </Grid >
  </>;
}

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default HistoricoComponent;
