import { useBaseActions } from '../../../base.actions';
import { consultorAtom } from './ConsultorState';

function useConsultorActions() {
  const baseActions = useBaseActions('consultores', consultorAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useConsultorActions };
