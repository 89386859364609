import React from "react";
import { useEffect, useState } from "react";
import {
  TextFieldStato,
  GridContainerCenter,
  ButtonStato,
  GridItem,
} from "../../../commons/styleds";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withRouter } from "react-router-dom";
import {
  IComanda,
  IContato,
  IPlanosTemplate,
  IdNome,
} from "../../../commons/genericTypes";
import { history } from "../../../helpers/history";
import {
  Accordion,
  AccordionDetails,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { styled } from "styled-components";
import { toast } from "react-toastify";
import { ModeloComercial, TipoConsultor } from "../../../commons/enums";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../states/config/CadastroUsuarios/usuarios/AuthState";
import { usePlanosTemplateActions } from "../../../states/config/Programas/planosTempletes/planosTemplate.actions";
import { GridEventListener } from "@mui/x-data-grid";
import { useComandaActions } from "../../../states/crm/comandas/comanda.actions";
import HeaderPages from "../../../components/Generics/HeaderPages";
import DrawerTemplates from "./components/DrawerTemplates";
import ModeloPretendido from "./components/ModeloPretendido";
import RestricaoEmpresa from "./components/RestricaoEmpresa";
import B2bCampos from "./components/B2bCampos";
import ContatoCampos from "./components/ContatoCampos";
import PlanoCampos from "./components/PlanoCampos";
import { NumericFormat } from "react-number-format";
import { matchIsValidTel } from "mui-tel-input";
import { possuiPermissao } from "../../../commons/validaPermissao";
import DrawerImportB2B from "./components/DrawerImportB2B";
import { useEmpresasActions } from "../../../states/empresas/empresa.actions";
import EmpresaCliente from "./components/EmpresaCliente";

const empty: IComanda = {
  modeloComercial: ModeloComercial.B2C,
  bus: TipoConsultor.Profissional,
  projetoConfidencial: false,
  contatos: [],
  plano: "",
  etapas: [],
  status: 0,
  comercialResponsavel: "",
  empresaCliente: "",
  rhRepresentante: "",
  estimativaAdesao: "",
  valorContrato: 0,
  taxaSucesso: '',
  recolocadoStato: false,
  recolocadoIndependenteForma: false,
  ultimaEmpresaTrabalho: "",
  regiaoMoradia: "",
  modeloTrabalho: "modeloRemoto",
  restricaoEmpresa: false,
  empresaDaRestricao: "",
  restricaoSegmento: false,
  segmentoDaRestricao: "",
  modeloContrato: "Indiferente",
  valorHora: undefined,
  reuniaoParceiros: false,
  relacionamentoMercado: false,
  coachingIntegracao: false,
};

export interface IImportarContatosB2B {
  anexo: any;
}

const Comanda: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const comandaActions = useComandaActions();
  const PlanosTempletesActions = usePlanosTemplateActions();

  const [open, setOpen] = useState<boolean>(false);
  const [openImportB2B, setOpenImportB2B] = useState<boolean>(false);
  const [novoUsuario, setNovoUsuario] = useState<boolean>(true);
  const [planosTemplates, setPlanosTemplete] = useState<IPlanosTemplate[]>([]);
  const [importarContatos, setImportarContatos] =
    useState<IImportarContatosB2B>();

  const modeloComercial = methods.watch("modeloComercial");
  const restricaoAlgumaSegmento = methods.watch("restricaoSegmento") === true;
  const restricaoAlgumaEmpresa = methods.watch("restricaoEmpresa") === true;
  const bus = methods.watch("bus");

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [veioDoBanco, setVeioDoBanco] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [salarioPretendido, setSalarioPretendido] = useState<
    number | undefined
  >();
  const [telefone, setTelefone] = useState<string>("");
  const [ultimoCargo, setUltimoCargo] = useState<string>("");
  const [ultimoSalario, setUltimoSalario] = useState<string>("");
  const [defaultPrazoPlano, setDefaultPrazoPlano] = useState<number>();

  const validaPermissao = () => {
    if (!possuiPermissao(auth, ["configuracoes sistema"])) {
      toast.warn("Você não possui permissão para acessar esse módulo");
      history.push("/");
    }
  };

  const getPlanosTemplate = () => {
    PlanosTempletesActions.list()
      .then((resp: IPlanosTemplate[]) => setPlanosTemplete(resp))
      .catch((err) => toast.warn(err));
  };

  useEffect(() => {
    validaPermissao();
    getPlanosTemplate();
    setLoading(false);
  }, []);

  const invalidProp = (prop: any, string: boolean = false) => {
    if (prop === undefined || prop === null) return true;
    if (string && prop === "") return true;
    return false;
  };

  const canSave = (data: IComanda) => {
    if (
      invalidProp(data.projetoConfidencial) ||
      invalidProp(data.modeloComercial) ||
      invalidProp(data.modeloContrato, true) ||
      invalidProp(data.restricaoEmpresa) ||
      invalidProp(data.valorContrato) ||
      invalidProp(data.restricaoSegmento) ||
      invalidProp(data.status)
    ) {
      toast.warn("Preencha as informações necessárias para salvar!");
      return false;
    }

    return true;
  };

  const onSubmit = async (data: IComanda) => {
    setLoadingSave(true);

    data.restricaoEmpresa = data.restricaoEmpresa.toString() == "true";
    data.restricaoSegmento = data.restricaoSegmento.toString() == "true";

    if (
      !matchIsValidTel(telefone) &&
      data.modeloComercial === ModeloComercial.B2C
    ) {
      toast.warn("Deve informar um telefone válido.");
      return;
    }

    if (data.modeloComercial === ModeloComercial.B2B) {
      const contatosImportados = await importarPlaninha();
      data.contatos = contatosImportados;
    }

    if (data.modeloComercial === ModeloComercial.B2C || (email && nome)) {
      const contato: IContato = {
        id: 0,
        ativo: true,
        email: email,
        nome: nome,
        salarioPretendido: salarioPretendido,
        telefone: telefone,
        ultimoCargo: ultimoCargo,
        ultimaRemuneracao: ultimoSalario,
      };
      data.contatos.push(contato);
    }

    if (!canSave(data) || data.contatos.length <= 0) return;

    try {
      await comandaActions.create(data);
      toast.success("Programa criado com sucesso!");
      history.push("/crm/contatos");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao Cadas rar!");
    }

    setLoadingSave(false);
  };

  const importarPlaninha = async () => {
    if (
      importarContatos === undefined ||
      importarContatos.anexo === undefined
    ) {
      return [];
    }

    const dataImport: any = {
      anexo: importarContatos?.anexo,
    };
    try {
      var resp = await comandaActions.createContatosB2BFormData(dataImport);
      return resp;
    } catch (error) {
      console.error(error);
      toast.error("Erro ao importar contatos!");
      setLoadingSave(false);
      throw error;
    }
  };

  const openDrawerCV = (result: any) => {
    setOpen(result);
  };

  const closeDrawerTemplates = (result: any) => {
    openDrawerCV(false);
  };

  const handleRowClickTemplate: GridEventListener<"rowClick"> = (
    params: any,
  ) => {
    if (params.row.etapas.length > 0) {
      methods.setValue(
        "etapas",
        params.row.etapas.map((m: IdNome) => m.id),
      );
      methods.setValue("plano", params.row.nome);
    }

    setDefaultPrazoPlano(params.row.periodoEmMeses);

    setOpen(false);
  };

  return (
    <>
      <HeaderPages
        actionBack={true}
        arrowBackClick="/crm/contatos"
        icon="person_add"
        title="Novo Programa"
      />

      <Grid
        item
        xs={12}
        md={12}
        sx={{ alignContent: "center", alignItems: "center" }}
        style={{ padding: "0px 30px" }}
      >
        {(loading || loadingSave) && <LinearProgress />}
        <FormProvider {...methods}>
          {loading === false && (
            <form id="formUsuario" onSubmit={methods.handleSubmit(onSubmit)}>
              <Accordion expanded={true} style={{ borderRadius: "10px" }}>
                <AccordionDetails>
                  <GridCadastro container id="cadastro-usuario">
                    <GridItemLinhaCadastro
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "flex-end",
                        alignContent: "center",
                      }}
                    >
                      <GridItemCadastro item xs={12} md={3}>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          Projeto Confidencial
                        </Typography>
                      </GridItemCadastro>

                      <GridItemCadastro item xs={12} md={9}>
                        <Controller
                          control={methods.control}
                          name="projetoConfidencial"
                          render={({ field }) => (
                            <RadioGroup
                              row
                              {...field}
                              aria-labelledby="radio-buttons-projetoConfidencial-label"
                              name="radio-buttons-projetoConfidencial"
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={"Sim, trabalhando"}
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={"Não"}
                              />
                            </RadioGroup>
                          )}
                        />
                      </GridItemCadastro>
                    </GridItemLinhaCadastro>

                    <GridItemLinhaCadastro
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "flex-end",
                        alignContent: "center",
                      }}
                    >
                      <GridItemCadastro item xs={12} md={3}>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          Modelo Comercial
                        </Typography>
                      </GridItemCadastro>

                      <GridItemCadastro item xs={12} md={9}>
                        <Controller
                          control={methods.control}
                          name="modeloComercial"
                          render={({ field }) => (
                            <RadioGroup
                              row
                              {...field}
                              aria-labelledby="radio-buttons-modeloComercial-label"
                              name="radio-buttons-modeloComercial"
                            >
                              <FormControlLabel
                                value={ModeloComercial.B2C}
                                control={<Radio />}
                                label={ModeloComercial.B2C}
                              />
                              <FormControlLabel
                                value={ModeloComercial.B2B}
                                control={<Radio />}
                                label={ModeloComercial.B2B}
                              />
                            </RadioGroup>
                          )}
                        />
                      </GridItemCadastro>
                    </GridItemLinhaCadastro>
                    <GridItemLinhaCadastro
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "flex-end",
                        alignContent: "center",
                      }}
                    >
                      <GridItemCadastro item xs={12} md={3}>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          BU
                        </Typography>
                      </GridItemCadastro>

                      <GridItemCadastro item xs={12} md={9}>
                        <Controller
                          control={methods.control}
                          name="bus"
                          render={({ field }) => (
                            <RadioGroup
                              row
                              {...field}
                              aria-labelledby="radio-buttons-modeloComercial-label"
                              name="radio-buttons-modeloComercial"
                            >
                              <FormControlLabel
                                value={TipoConsultor.Exec}
                                control={<Radio />}
                                label={TipoConsultor.Exec}
                              />
                              <FormControlLabel
                                value={TipoConsultor.Prime}
                                control={<Radio />}
                                label={TipoConsultor.Prime}
                              />
                              <FormControlLabel
                                value={TipoConsultor.Profissional}
                                control={<Radio />}
                                label={TipoConsultor.Profissional}
                              />
                            </RadioGroup>
                          )}
                        />
                      </GridItemCadastro>
                    </GridItemLinhaCadastro>

                    {modeloComercial === ModeloComercial.B2B && (
                      <>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <GridItemCadastro item xs={12}>
                          <B2bCampos novoUsuario={novoUsuario} />
                        </GridItemCadastro>
                      </>
                    )}

                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <Separador></Separador>
                    </GridItemLinhaCadastro>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <Separador />
                    </GridItemLinhaCadastro>

                    {modeloComercial === ModeloComercial.B2B && (
                      <>
                        <ButtonStato
                          variant="contained"
                          onClick={() => setOpenImportB2B(true)}
                        >
                          Importar Contatos
                        </ButtonStato>

                        <DrawerImportB2B
                          open={openImportB2B}
                          onClose={() => setOpenImportB2B(false)}
                          obj={importarContatos}
                          setObj={setImportarContatos}
                          loading={loading}
                        />
                        <GridItemLinhaCadastro item xs={12} md={12}>
                          <Separador />
                        </GridItemLinhaCadastro>
                      </>
                    )}

                    <ContatoCampos
                      setEmail={setEmail}
                      email={email}
                      setNome={setNome}
                      nome={nome}
                      setTelefone={setTelefone}
                      telefone={telefone}
                      setUltimoCargo={setUltimoCargo}
                      ultimoCargo={ultimoCargo}
                      setUltimoSalario={setUltimoSalario}
                      ultimoSalario={ultimoSalario}
                      setSalarioPretendido={setSalarioPretendido}
                      salarioPretendido={salarioPretendido}
                      setVeioDoBanco={setVeioDoBanco}
                      veioDoBanco={veioDoBanco}
                      setNovoUsuario={setNovoUsuario}
                      novoUsuario={novoUsuario}
                      b2b={modeloComercial === ModeloComercial.B2B}
                    />

                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <Separador></Separador>
                    </GridItemLinhaCadastro>
                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <Separador></Separador>
                    </GridItemLinhaCadastro>

                    <PlanoCampos
                      novoUsuario={novoUsuario}
                      openDrawer={openDrawerCV}
                      defaultPrazoPlano={defaultPrazoPlano}
                      modeloComercial={ModeloComercial[modeloComercial as keyof typeof ModeloComercial]}
                      bu={bus}
                    />

                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <Separador></Separador>
                    </GridItemLinhaCadastro>
                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <Separador></Separador>
                    </GridItemLinhaCadastro>

                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <GridItemCadastro item xs={12} md={4}>
                        <Controller
                          control={methods.control}
                          name="valorContrato"
                          render={({ field }) => (
                            <FormControl required fullWidth variant="outlined">
                              <NumericFormat
                                {...field}
                                onChange={(e) => console.debug(e.target.value)}
                                onValueChange={(e) => field.onChange(e.value)}
                                prefix="R$"
                                thousandSeparator="."
                                decimalSeparator=","
                                label="Valor do Contrato"
                                customInput={TextFieldStato}
                                valueIsNumericString
                                allowLeadingZeros={false}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </FormControl>
                          )}
                        />
                      </GridItemCadastro>
                      <GridItemCadastro item xs={12} md={6}>
                        <Controller
                          control={methods.control}
                          name="taxaSucesso"
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant="outlined">
                              <TextFieldStato
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Taxa de Sucesso"
                              />
                            </FormControl>
                          )}
                        />
                      </GridItemCadastro>
                    </GridItemLinhaCadastro>

                    <GridItemLinhaCadastro>
                      <GridItemCadastro item xs={12} md={6}>
                        <Controller
                          control={methods.control}
                          name='recolocadoStato'
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label='Taxa de Sucesso se recolocado Stato'
                            />
                          )}
                        />
                      </GridItemCadastro>
                      <GridItemCadastro item xs={12} md={6}>
                        <Controller
                          control={methods.control}
                          name='recolocadoIndependenteForma'
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label='Taxa de sucesso se recolocado independente fonte'
                            />
                          )}
                        />
                      </GridItemCadastro>
                    </GridItemLinhaCadastro>

                    <GridItemLinhaCadastro item xs={12} md={12}>
                      <GridItemCadastro item xs={12} md={6}>
                        {modeloComercial !== ModeloComercial.B2B && (
                          <EmpresaCliente
                            label="Empresa Cliente"
                            setEmpresaValue={(value?: string) => methods.setValue('tbEmpresaId', Number(value))}
                          />
                        )}
                      </GridItemCadastro>
                      <GridItemCadastro item xs={12} md={4}>
                        <Controller
                          control={methods.control}
                          name="regiaoMoradia"
                          render={({ field }) => (
                            <FormControl required fullWidth variant="outlined">
                              <TextFieldStato
                                {...field}
                                type="regiaoMoradia"
                                label="Região Moradia"
                              />
                            </FormControl>
                          )}
                        />
                      </GridItemCadastro>
                    </GridItemLinhaCadastro>
                  </GridCadastro>
                </AccordionDetails>
              </Accordion>

              <DrawerTemplates
                open={open}
                onClose={closeDrawerTemplates}
                handleClick={handleRowClickTemplate}
                planosTemplates={planosTemplates}
                loading={loading}
              />

              <GridItemCadastroBtnSalvar item xs={11} md={11}>
                <ButtonStato type="submit" variant="contained">
                  Salvar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </form>
          )}
        </FormProvider>
      </Grid>
    </>
  );
};

const GridCadastro = styled(GridContainerCenter)`
  width: 97% !important;
  margin-right: 0px !important;
  display: flex;
  justify-content: space-between !important;
  padding: 15px 0%;
`;

export const GridItemCadastro = styled(Grid)`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
`;

export const Separador = styled(Grid)`
  padding: 3px;
`;

export const GridItemLinhaCadastro = styled(Grid)`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between !important;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  position: fixed;
  bottom: 2%;
  right: 4%;
  z-index: 50;
`;

export default withRouter(Comanda);
