import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';
import { mercadoAlvoAtom } from './MercadoAlvoState';

function useMercadoAlvoActions() {
  const baseActions = useBaseActions('mercadoAlvos', mercadoAlvoAtom);
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };

}

export { useMercadoAlvoActions };
