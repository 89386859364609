import { useBaseActions } from '../../base.actions';
import { eventoAtom } from './EventoState';


function useEventoActions() {
  const baseActions = useBaseActions('Evento', eventoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useEventoActions };
