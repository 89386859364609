import { GridContainer } from "../../commons/styleds";
import React from "react";

const LogSistema: React.FC = () => {
  return (
    <GridContainer id="home"
      container
      style={{
        display: "flex",
        alignContent: "space-around",
        justifyContent: "space-around",
        zIndex: 999999
      }}
    >
      <h1>Log do Sistema [Em Construção]!</h1>
    </GridContainer>
  );
};

export default LogSistema;
