import { metodologiaAtom } from '../metodologias/MetodologiaState';
import { useBaseActions } from '../../../base.actions';

function useMetodologiaActions() {
  const baseActions = useBaseActions('metodologias', metodologiaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useMetodologiaActions };
