import { Controller, useFormContext } from 'react-hook-form';
import { GridItemCadastro, GridItemLinhaCadastro, Separador } from '..';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  createFilterOptions,
} from '@mui/material';
import { ButtonStato, TextFieldStato } from '../../../../commons/styleds';
import { useEmpresasActions } from '../../../../states/empresas/empresa.actions';
import { useEffect, useState } from 'react';
import { IEmpresa } from '../../../../commons/genericTypes';
import { toast } from 'react-toastify';
import EmpresaCliente from './EmpresaCliente';

interface IB2bCampos {
  novoUsuario: boolean;
}

const B2bCampos: React.FC<IB2bCampos> = (props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  return (
    <>
      <Separador />
      <Separador />
      <GridItemLinhaCadastro item xs={12} md={12}>
        <GridItemCadastro item xs={12} md={6}>
          <Controller
            control={methods.control}
            name='comercialResponsavel'
            render={({ field }) => (
              <FormControl required fullWidth variant='outlined'>
                <TextFieldStato
                  {...field}
                  type='comercialResponsavel'
                  label='Comercial Responsável'
                  disabled={!novoUsuario}
                />
              </FormControl>
            )}
          />
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={6}>
          <EmpresaCliente
            label='Empresa Cliente'
            setEmpresaValue={(value?: string) => methods.setValue('tbEmpresaId', Number(value))}
          />
        </GridItemCadastro>
      </GridItemLinhaCadastro>

      <GridItemLinhaCadastro item xs={12} md={12}>
        <GridItemCadastro item xs={12} md={6}>
          <Controller
            control={methods.control}
            name='rhRepresentante'
            render={({ field: { ref, ...field } }) => (
              <FormControl required fullWidth variant='outlined'>
                <TextFieldStato
                  {...field}
                  inputRef={ref}
                  value={field.value}
                  label='RH Representante'
                />
              </FormControl>
            )}
          />
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={6}>
          <Controller
            control={methods.control}
            name='estimativaAdesao'
            render={({ field }) => (
              <FormControl required fullWidth variant='outlined'>
                <TextFieldStato
                  {...field}
                  type='estimativaAdesao'
                  label='Estimativa Adesao'
                  disabled={!novoUsuario}
                />
              </FormControl>
            )}
          />
        </GridItemCadastro>
      </GridItemLinhaCadastro>
    </>
  );
};

export default B2bCampos;
