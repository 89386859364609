import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { TextFieldStato } from '../../../../commons/styleds';
import InputArquivoMethods from '../../../../components/InputArquivo/InputArquivoMethods';

interface Props {
    editAcao: boolean;
    arquivoUrl: any;
    setArquivoUrl: any;
    setNomeArquivo: any;
    nomeArquivo: any;
}

const ConteudoStepper3: React.FC<Props> = (props: Props) => {
    const { editAcao, arquivoUrl } = props;
    const methods = useFormContext();
    const editMethods = useFormContext();

    return (
        <>
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                <InputArquivoMethods
                    isEdit={editAcao}
                    objOnEdit={editMethods}
                    setObjOnEdit={editMethods}
                    newObj={methods}
                    setNewObj={methods}
                    nameFile={props.nomeArquivo}
                    setNameFile={props.setNomeArquivo}
                    urlFile={arquivoUrl!}
                    setUrlFile={props.setArquivoUrl}
                    propriedade={"anexoFile"}
                />

                <Grid item xs={12} md={4} sm={4}>
                    <Controller
                        control={methods.control}
                        name='ativo'
                        render={({ field }) => (
                            <FormControlLabelStato
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    //defaultChecked={true}
                                    />
                                }
                                label='Ativo'
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                    <Controller
                        control={methods.control}
                        name='visivel'
                        render={({ field }) => (
                            <FormControlLabelStato
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    //defaultChecked={true}
                                    />
                                }
                                label='Visivel Portal Assessorado'
                            />
                        )}
                    />
                </Grid>

            </Grid>
        </>
    );
};


const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default ConteudoStepper3;
