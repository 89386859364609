import { useBaseActions } from '../../../base.actions';
import { telefoneAtom } from './TelefoneState';

function useTelefoneActions() {
  const baseActions = useBaseActions('telefones', telefoneAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useTelefoneActions };
