import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Logo from './../../assets/Logo-Stato-Intoo_1.png';

import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from 'styled-components';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PPCStepper1 from './Steps/stepper1';
import PPCStepper2 from './Steps/stepper2';
import PPCStepper3 from './Steps/stepper3';
import PPCStepper4 from './Steps/stepper4';
import PPCStepper5 from './Steps/stepper5';
import { FormProvider, useForm } from 'react-hook-form';
import PPCStepper6 from './Steps/stepper6';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { CircularProgress, Divider } from '@mui/material';
import { usePerfilActions } from '../../../states/crm/perfis/perfil.actions';
import { useParams } from 'react-router-dom';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ width: '50% !important' }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const empty: IAnalisePerfil = {
    id: 0,
    contatoId: 0,
    informacaoGeralP1: '',
    informacaoGeralP2: '',
    informacaoGeralP3: '',
    informacaoGeralP4: '',
    informacaoGeralP5: '',
    informacaoGeralP6: '',
    informacaoGeralP7: '',
    informacaoGeralP8: '',
    informacaoGeralP9: '',
    informacaoGeralP10: '',
    informacaoGeralP11: '',
    informacaoGeralP12: '',
};

export interface IAnalisePerfil {
    id: number;
    contatoId: number;
    informacaoGeralP1?: string;
    informacaoGeralP2?: string;
    idFaixaRemuneracao?: number;
    informacaoGeralP3?: string;
    informacaoGeralP4?: string;
    informacaoGeralP5?: string;
    informacaoGeralP6?: string;
    informacaoGeralP7?: string;
    informacaoGeralP8?: string;
    informacaoGeralP9?: string;
    informacaoGeralP10?: string;
    informacaoGeralP11?: string;
    informacaoGeralP12?: string;
    referencia?: string;
    resultadosRealizacoesCompetencias?: any[];
    analiseSwotForteP1?: string;
    analiseSwotForteP2?: string;
    analiseSwotForteP3?: string;
    analiseSwotForteP4?: string;
    analiseSwotForteP5?: string;
    analiseSwotForteP6?: string;
    analiseSwotForteP7?: string;
    analiseSwotFracoP1?: string;
    analiseSwotFracoP2?: string;
    analiseSwotFracoP3?: string;
    analiseSwotFracoP4?: string;
    analiseSwotFracoP5?: string;
    analiseSwotFracoP6?: string;
    analiseSwotFracoP7?: string;
    analiseSwotFracoP8?: string;
    analiseSwotOportunidadeP1?: string;
    analiseSwotOportunidadeP2?: string;
    analiseSwotOportunidadeP3?: string;
    analiseSwotOportunidadeP4?: string;
    analiseSwotOportunidadeP5?: string;
    analiseSwotAmeacaP1?: string;
    analiseSwotAmeacaP2?: string;
    analiseSwotAmeacaP3?: string;
    analiseSwotAmeacaP4?: string;
    analiseSwotAmeacaP5?: string;
    analiseSwotAmeacaP6?: string;
    analiseSwotForcaOrigem?: string;
    analiseSwotOportunidadeOrigem?: string;
    analiseSwotFraquezaOrigem?: string;
    analiseSwotAmeacaOrigem?: string;
    reflexaoDeCarreiraP1?: string;
    reflexaoDeCarreiraP2?: string;
    reflexaoDeCarreiraP3?: string;
    reflexaoDeCarreiraP4?: string;
    reflexaoDeCarreiraP5?: string;
    reflexaoDeCarreiraP6?: string;
    reflexaoDeCarreiraP7?: string;
    reflexaoDeCarreiraP8?: string;
    reflexaoDeCarreiraP9?: string;
    estiloDeLiderancaP1?: string;
    estiloDeLiderancaP2?: string;
    estiloDeLiderancaP3?: string
}

interface IParams {
    id?: string;
}

const PPC: React.FC = () => {
    const { id } = useParams<IParams>();
    const [value, setValue] = React.useState(0);

    const methods = useForm({ defaultValues: empty });

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const teste = methods.watch('informacaoGeralP1');

    const analisePerfisActions = usePerfilActions();

    // const analisePerfisActions = useAnalisePerfisActions();
    const [analisePerfil, setAnalisePerfil] = useState<IAnalisePerfil>();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const onSubmit = (data: IAnalisePerfil) => {
        // setLoadingSave(true);
        // data.contatoId = auth?.id!;
        // // if (validarTabs(data))
        // //     return;

        // if (data.id <= 0 || data.id == undefined) {
        //     analisePerfisActions.create(data)
        //         .then((resp: IAnalisePerfil) => {
        //             atualizarFormulario(resp);
        //             toast.success("Salvo com sucesso !");
        //             setLoadingSave(false);
        //         })
        //         .catch((err: any) => {
        //             setLoadingSave(false);
        //             toast.warn(err)
        //         });
        // } else {
        //     analisePerfisActions.update(data.id, data)
        //         .then((resp: IAnalisePerfil) => {
        //             atualizarFormulario(resp);
        //             toast.success("Salvo com sucesso !");
        //             setLoadingSave(false);
        //         })
        //         .catch((err: any) => {
        //             setLoadingSave(false);
        //             toast.warn(err);
        //         });
        // }
    };

    const handleChangeNext = () => {
        if (value < 5)
            setValue(value + 1);
    };

    const handleChangeBack = () => {
        if (value > 0)
            setValue(value - 1);
    };

    const getPPC = () => {

        setLoading(true);
        analisePerfisActions.list(false, `?contatoId=${id}`).then((resp: IAnalisePerfil[]) => {

            if (resp.length > 0) {
                methods.reset(resp[0], { keepDefaultValues: true });
            }
            console.log("Limpou");
            setTimeout(() => {
                setLoading(false);
            }, 1500);

        }).catch((err: any) => toast.warn(err));
    };

    useEffect(() => {

        getPPC();

    }, []);

    return (
        <GridContainer container direction="row" spacing={0} sx={{ display: "flex", backgroundColor: "white" }}>
            <Grid container direction="row" spacing={0} sx={{ display: "flex", flexWrap: "wrap", alignContent: "flex-start" }} xs={12} md={12}>

                <Grid xs={12} md={12}>
                    <Typography sx={{ padding: "10px" }} variant="h4" component="h2">
                        Análise de Perfil e Planejamento de Carreira
                    </Typography>
                    <Divider orientation="horizontal" />
                </Grid>

                {/* <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
                    <Typography sx={{ padding: "10px" }} variant="caption">
                        Este questionário levantará informações importantes para seu consultor conhecê-lo (a) melhor, além de ser essencial para uma reflexão estruturada e melhor planejamento de sua carreira. Revisitar sua trajetória profissional e realizações, mapeando competências e oportunidades de melhoria, é a base da nossa metodologia para a construção do plano de ação para os próximos ciclos da sua carreira. Este material também será utilizado para a elaboração do currículo, definição de estratégias de posicionamento e comunicação.
                        Concentre-se e bom trabalho!
                    </Typography>
                </Grid> */}

                <FormProvider {...methods}>
                    <FormStato
                        id='formArea'
                        onSubmit={methods.handleSubmit(onSubmit)}
                        style={{ width: '100%' }}
                    >
                        <Grid container xs={12} md={12} sm={12} lg={12}>
                            <Box sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center"
                            }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="# 1" {...a11yProps(0)} />
                                    <Tab label="# 2" {...a11yProps(1)} />
                                    <Tab label="# 3" {...a11yProps(2)} />
                                    <Tab label="# 4" {...a11yProps(3)} />
                                    <Tab label="# 5" {...a11yProps(4)} />
                                    <Tab label="# 6" {...a11yProps(5)} />
                                    {/* <Tab label="# 7" {...a11yProps(6)} /> */}
                                </Tabs>

                                <Box sx={{ width: '100%', paddingTop: "5px" }}>
                                    {loadingSave &&
                                        <LinearProgress />
                                    }
                                </Box>
                            </Box>
                        </Grid>

                        <Grid container xs={12} md={12} sm={12} sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                            <CustomTabPanel value={value} index={0}>
                                <PPCStepper1 novoUsuario={false}></PPCStepper1>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <PPCStepper2 novoUsuario={false}></PPCStepper2>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <PPCStepper3 novoUsuario={false}></PPCStepper3>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <PPCStepper4 novoUsuario={false}></PPCStepper4>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                                <PPCStepper5 novoUsuario={false}></PPCStepper5>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={5}>
                                <PPCStepper6 novoUsuario={false}></PPCStepper6>
                            </CustomTabPanel>
                        </Grid>

                        <Grid container xs={12} md={12} sm={12} sx={{
                            margin: "0px 20px",
                        }}>
                            <Grid container xs={6} md={6} sm={6}>
                                <ButtonHome
                                    // type="submit"
                                    variant="contained"
                                    onClick={handleChangeBack}
                                    disabled={value === 0}
                                >
                                    Anterior
                                </ButtonHome>
                            </Grid>
                            <Grid container xs={6} md={6} sm={6}
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <ButtonHome
                                    // type="submit"
                                    variant="contained"
                                    onClick={handleChangeNext}
                                    disabled={value === 5}
                                >
                                    Próximo
                                </ButtonHome>
                            </Grid>
                        </Grid>

                        {/* <Grid container xs={12} md={12} sm={12}
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "flex-end",
                                margin: "20px",
                            }}
                        >
                            <ButtonHome
                                type="submit"
                                variant="contained"
                                disabled={loadingSave}
                            // onClick={handleChangeNext}
                            >
                                {loadingSave ??
                                    <CircularProgress size={20} sx={{
                                        marginRight: "10px",
                                        color: "#b9b9b9"
                                    }} />
                                }
                                Salvar
                            </ButtonHome>
                        </Grid> */}

                    </FormStato>
                </FormProvider>

            </Grid>
        </GridContainer >
    );
};

const FormStato = styled.form`
`;

const ButtonHome = styled(Button)`
  color: white !important;
  border-radius: 10px !important;
`;

export const GridContainer = styled(Grid)`
  margin: 0px !important;
  height: 100% !important;
  width: 100%;
`;


export default PPC;

