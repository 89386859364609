import { nivelFluenciaAtom } from './NivelFluenciaState';
import { useBaseActions } from '../../../base.actions';

function useNivelFluenciaConfigActions() {
  const baseActions = useBaseActions('configNivelFluencias', nivelFluenciaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useNivelFluenciaConfigActions };
