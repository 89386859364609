import { Box, Button, Checkbox, CircularProgress, createTheme, Dialog, DialogActions, DialogTitle, FormControl, FormControlLabel, Grid, Icon, LinearProgress, ThemeProvider } from '@mui/material';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { usePerfilCompetenciasActions } from '../../../../states/crm/perfilCompetencias/perfilCompetencia.actions';
import { useCompetenciaActions } from '../../../../states/crm/competencias/competencia.actions';
import { IResultadoRealizacaoCompetencia } from '../../../../commons/genericTypes';
// import { useCompetenciasActions } from '../../../states/competencia/competencia.actions';
// import { usePerfilCompetenciasActions } from '../../../states/perfilCompetencias/perfilCompetencia.actions';
// import { authAtom } from '../../../states/usuarios/AuthState';

export interface ICompetencia {
  id: number;
  descricao?: string;
  ativo?: boolean
}

interface Props {
  novoUsuario: boolean;
}

const empty: IResultadoRealizacaoCompetencia = {
  id: 0,
  problemaDesafio: '',
  acao: '',
  sintese: '',
  resultado: '',
  idCompetencias: [],
  tbCompetencias: [],
  ativo: true,
};

const PerfilRealizacoes: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;

  const methods = useFormContext();
  const competenciasActions = useCompetenciaActions();
  const ppcCompetenciasActions = usePerfilCompetenciasActions();
 
  const auth = useRecoilValue(authAtom);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(true);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [perfilRealizacoes, setResultadoRealizacaoCompetencias] = useState<IResultadoRealizacaoCompetencia[]>([]);
  const [perfilRealizacoesTemp, setResultadoRealizacaoCompetenciaTemp] = useState<IResultadoRealizacaoCompetencia>();
  const [selectedResultadoRealizacaoCompetencias, setSelectedResultadoRealizacaoCompetencias] = useState<
    IResultadoRealizacaoCompetencia[] | undefined
  >(undefined);

  const id = methods.watch("id");

  const [competencias, setCompetencias] = useState<ICompetencia[]>([]);
  const [competencia, setCompetencia] = useState<ICompetencia>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 60,
    },
    {
      field: 'problemaDesafio',
      headerName: 'Problema - Desafio',
      width: 200,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 160,
    },
    {
      field: 'resultado',
      headerName: 'Resultados',
      width: 160,
    },
    {
      field: 'sintese',
      headerName: 'Síntese',
      width: 160,
    },
    {
      field: 'tbCompetencias',
      headerName: 'Competências',
      width: 250,
      valueGetter: (params: GridCellParams) => {
        const competencias = params.row.tbCompetencias as ICompetencia[];
        const descricoes = competencias.map((comp) => comp.descricao);
        const mostrar = descricoes.join(' ; ');
        return mostrar;
      }
    }
  ];

  useEffect(() => {

    competenciasActions
      .list()
      .then((resp: ICompetencia[]) => setCompetencias(resp))
      .catch((err: any) => toast.warn(err));

    ppcCompetenciasActions
      .list(false, `?IdContatoPerfilPlanejamento=${id}`)
      .then((resp: IResultadoRealizacaoCompetencia[]) => {
        setResultadoRealizacaoCompetencias(resp);
      })
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <>

      <Grid item md={0.5}></Grid>

      <Grid container xs={12} md={12} sm={12}>
        <Box
          sx={{
            height: '55vh',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id}_${Math.floor(Math.random() * 20)}`}
              rows={perfilRealizacoes ?? []}
              columns={columns}
              loading={loading}
            />
          </ThemeProvider>
        </Box>
      </Grid>
    </>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const FormStato = styled.form`
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default PerfilRealizacoes;
