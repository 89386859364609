import { areaAtom } from './AreaState';
import { useBaseActions } from '../base.actions';

function useAreaActions() {
  const baseActions = useBaseActions('areas', areaAtom);

  return {
    get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };

  async function get(salvarNoState: boolean = false) {
    return await baseActions.list(salvarNoState);
  }
}

export { useAreaActions };
