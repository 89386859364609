import { useBaseActions } from '../../../base.actions';
import { moduloAtom } from './ModuloState';

function useModuloActions() {
  const baseActions = useBaseActions('modulos', moduloAtom);
 
  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useModuloActions };
