import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  ptBR,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import {
  IComanda,
  IComandaEtapaReunioes,
  IConsultor,
  IEtapa,
  IOnboarding,
  IStatus,
  IUsuario,
} from '../../../../commons/genericTypes';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { useParams } from 'react-router-dom';
import { useComandaActions } from '../../../../states/crm/comandas/comanda.actions';
import { ButtonStato, SelectStato, TextFieldStato } from '../../../../commons/styleds';
import MultiSelectNome from '../../../../components/Generics/MultiSelectNome';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { GridItemCadastro, GridItemLinhaCadastro } from '../../Comanda';
import { Controller, useForm } from 'react-hook-form';
import { ModeloComercial } from '../../../../commons/enums';
import { CheckBox } from '@mui/icons-material';
import { styled } from 'styled-components';
import Divider from '@mui/material/Divider';
import ReuniaoComponent from './components/Reunioes';
import HistoricoComponent from './components/Historico';
import { useUsuarioActions } from '../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { possuiPermissao } from '../../../../commons/validaPermissao';
import { useConsultorActions } from '../../../../states/config/CadastroUsuarios/consultores/consultor.actions';
import { useStatusActions } from '../../../../states/config/Programas/status/status.actions';
import { NumericFormat } from 'react-number-format';
import Onboarding from './components/Onboarding';
import EmpresaCliente from '../../Comanda/components/EmpresaCliente';

interface IParams {
  id?: string;
}

const emptyComanda: IComanda = {
  modeloComercial: ModeloComercial.B2C,
  projetoConfidencial: false,
  contatos: [],
  plano: '',
  etapas: [],
  comandaEtapaReunioes: [],
  idStatus: 0,
  comercialResponsavel: '',
  empresaCliente: '',
  rhRepresentante: '',
  estimativaAdesao: '',
  valorContrato: 0,
  taxaSucesso: '',
  ultimaEmpresaTrabalho: '',
  regiaoMoradia: '',
  modeloTrabalho: 'modeloRemoto',
  restricaoEmpresa: false,
  empresaDaRestricao: '',
  restricaoSegmento: false,
  segmentoDaRestricao: '',
  modeloContrato: '',
  valorHora: undefined,
  reuniaoParceiros: false,
  relacionamentoMercado: false,
  coachingIntegracao: false,
  dataExtensao: null,
};

const ProgramaCrm: React.FC = () => {
  const { id } = useParams<IParams>();
  const methods = useForm({ defaultValues: emptyComanda });

  const auth = useRecoilValue(authAtom);
  const comandaActions = useComandaActions();
  const statusActions = useStatusActions();
  const [status, setStatus] = useState<IStatus[]>([]);
  const consultorActions = useConsultorActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [comandas, setComandas] = useState<IComanda[]>([]);
  const ComandaId: number | undefined = methods.watch('id');

  const [tempoProjeto, setTempoProjeto] = useState<string>('');

  const [comanda, setComanda] = useState<IComanda | undefined>();
  const [consultorOnboardings, setConsultorOnboardings] = useState<
    IConsultor[]
  >([]);

  const columns: GridColDef[] = [
    {
      field: 'plano',
      headerName: 'Plano',
      width: 100,
    },
    {
      field: 'etapas',
      headerName: 'Etapas',
      width: 350,
      valueGetter: (params: GridCellParams) => {
        const etapas = params.row.etapas as IEtapa[];
        const descricoes = etapas.map((etapa) => etapa.nome);
        const mostrar = descricoes.join(' ; ');
        return mostrar;
      },
    },
    {
      field: 'modeloComercial',
      headerName: 'Modelo Comercial',
      width: 150,
    },
    {
      field: 'projetoConfidencial',
      headerName: 'Confidencial',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComanda;
        return (
          <Checkbox
            checked={obj.projetoConfidencial}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    comandaActions.list(false, `?contatoId=${id}`).then((data) => {
      setComandas(data);
      setLoading(false);
    });

    consultorActions
      .list()
      .then((resp: any) => {
        setConsultorOnboardings(resp.resultado);
      })
      .catch((err: any) => toast.warn(err));

    statusActions
      .list()
      .then((resp) => setStatus(resp))
      .catch((err) => toast.warn(err));
  }, []);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    setComanda(undefined);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      const row = {
        ...params.row,
        dataInicio: dayjs(params.row.dataInicio),
        dataFim: dayjs(params.row.dataFim),
        dataExtensao: params.row.dataExtensao ? dayjs(params.row.dataExtensao) : null,
      };
      setComanda(row);
      calcularTempoProjeto(new Date(row.dataInicio), new Date(row.dataFim));
      methods.reset(row, { keepDefaultValues: true })
    }, 1000);
  };

  const calcularTempoProjeto = (dataInicio: Date, dataFim: Date) => {
    let anos = dataFim.getFullYear() - dataInicio.getFullYear();
    let meses = dataFim.getMonth() - dataInicio.getMonth();
    if (meses < 0) {
      anos--;
      meses += 12;
    }

    let anoDescricao = '';
    if (anos === 1) anoDescricao += '1 ano';
    else if (anos > 1) anoDescricao += `${anos} anos`;

    let mesDescricao = '';
    if (meses === 1) mesDescricao += '1 mês';
    else if (meses > 1) mesDescricao += `${meses} meses`;

    if (Boolean(anoDescricao && mesDescricao))
      setTempoProjeto(`${anoDescricao} e ${mesDescricao}`);
    else if (Boolean(anoDescricao))
      setTempoProjeto(anoDescricao);
    else if (Boolean(mesDescricao))
      setTempoProjeto(mesDescricao);
  }

  const handleRowClickHistorico: GridEventListener<'rowClick'> = (
    params: any
  ) => { };

  const onSubmit = async (data: IComanda) => {
    setLoading(true);
    try {
      await comandaActions.update(data.id!, {
        ...data,
        etapas: [],
        contatos: [],
      });
      toast.success('Comanda editada');
    } catch (e: any) {
      console.error(e);
      toast.error('Falha ao editar comanda');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '300px',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id}_${row?.cep}`}
              onRowClick={handleRowClick}
              rows={comandas ?? []}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>
        </Box>
      </Grid>
      <br />
      {loading && <LinearProgress />}

      {comanda && (
        <form id="formUsuario" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item sm={12} sx={{ marginTop: '30px' }}>
            <Grid container sm={12} spacing={2}>
              <Grid item sm={2}>
                <TextFieldStato
                  id='modeloComercial'
                  type='modeloComercial'
                  label='Modelo Comercial'
                  fullWidth
                  value={comanda?.modeloComercial}
                  disabled
                />
              </Grid>

              <GridItemCadastro
                item
                xs={12}
                sm={10}
                md={10}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                }}
              >
                <Controller
                  control={methods.control}
                  name='reclamante'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Reclamante'
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name='ra'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='RA'
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name='sac'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='SAC'
                    />
                  )}
                />

                <Grid >
                  <b style={{ fontSize: "18px" }}>Comanda ID #{comanda?.id}</b>
                </Grid>
              </GridItemCadastro>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
              <Grid item sm={6}>
                <TextFieldStato
                  id='plano'
                  type='plano'
                  label='Plano'
                  fullWidth
                  value={comanda?.plano}
                  disabled
                />
              </Grid>

              <Grid item sm={6} md={2}>
                <Controller control={methods.control} name="dataInicio" render={({ field }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      {...field}
                      label='Data Início'
                      format='DD/MM/YYYY'
                    />
                  </LocalizationProvider>
                )} />
              </Grid>

              <Grid item sm={6} md={2}>
                <Controller control={methods.control} name="dataFim" render={({ field }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      {...field}
                      label='Data Fim'
                      format='DD/MM/YYYY'
                    />
                  </LocalizationProvider>
                )} />
              </Grid>

              <Grid item sm={6} md={2}>
                <Controller control={methods.control} name="dataExtensao" render={({ field }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      {...field}
                      value={field.value ?? null}
                      label='Extensão'
                      format='DD/MM/YYYY'
                    />
                  </LocalizationProvider>
                )} />
              </Grid>

              <Grid item sm={5}>
                <Controller
                  control={methods.control}
                  name='idStatus'
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Status
                      </InputLabel>
                      <SelectStato {...field} required label='Status'>
                        {status.map((statu: IStatus, index: number) => (
                          <MenuItem value={statu.id} key={index}>
                            {statu.nome}
                          </MenuItem>
                        ))}
                      </SelectStato>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item sm={4}>
                <TextFieldStato
                  id='tempoProjeto'
                  type='tempoProjeto'
                  label='Tempo do Projeto'
                  value={tempoProjeto}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item sm={4} md={4}>
                <Controller
                  control={methods.control}
                  name='valorContrato'
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <NumericFormat
                        {...field}
                        onChange={(e) => console.debug(e.target.value)}
                        onValueChange={(e) => field.onChange(e.value)}
                        prefix="R$"
                        thousandSeparator="."
                        decimalSeparator=","
                        label="Valor do Contrato"
                        customInput={TextFieldStato}
                        valueIsNumericString
                        allowLeadingZeros={false}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item sm={4} md={4}>
                <Controller
                  control={methods.control}
                  name='taxaSucesso'
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextFieldStato
                        id='valorFee'
                        type='valorFee'
                        label='Taxa de Sucesso'
                        {...field}
                        fullWidth
                        disabled
                      />
                    </FormControl>
                  )}
                />
              </Grid>

              <GridItemCadastro item xs={12} sm={4} md={4}>
                <Controller
                  control={methods.control}
                  name='recolocadoStato'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Taxa de Sucesso se recolocado Stato'
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name='recolocadoIndependenteForma'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Taxa de sucesso se recolocado independente da forma'
                    />
                  )}
                />
              </GridItemCadastro>

              <Grid item sm={12}>
                {comanda?.etapas && (
                  <FormControl required fullWidth variant='outlined'>
                    <MultiSelectNome
                      opcoes={comanda?.etapas as IEtapa[]}
                      setValor={() => {
                        return;
                      }}
                      valor={comanda?.etapas.map((e) => e.id) || []}
                      label='Etapas/Reuniões'
                    />
                  </FormControl>
                )}
              </Grid>

              <GridItemCadastro item xs={12} sm={4} md={4}>
                {
                  comanda.modeloComercial === ModeloComercial.B2B &&
                  <Controller
                    control={methods.control}
                    name='tbEmpresaId'
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <EmpresaCliente
                          label="Empresa Cliente"
                          setEmpresaValue={(value?: string) => methods.setValue('tbEmpresaId', Number(value))}
                        />
                      </FormControl>
                    )}
                  />
                }
              </GridItemCadastro>

              <GridItemCadastro item xs={12} sm={4} md={4}>
                <Controller
                  control={methods.control}
                  name='contatoRelatorio'
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Contato Relatório
                      </InputLabel>
                      <SelectStato {...field} label='Contato Relatório'>
                      </SelectStato>
                    </FormControl>
                  )}
                />
              </GridItemCadastro>

              <GridItemCadastro item xs={12} sm={12} md={12}>
                <Controller
                  control={methods.control}
                  name='reuniaoParceiros'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Reunião com Parceiros'
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name='relacionamentoMercado'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Relacionamento/Mercado'
                    />
                  )}
                />
                <Controller
                  control={methods.control}
                  name='coachingIntegracao'
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label='Coaching de Integração'
                    />
                  )}
                />
              </GridItemCadastro>

              <GridItemCadastro item xs={12} sm={4} md={4}>
                <Controller
                  control={methods.control}
                  name='comercialResponsavel'
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Comercial Responsável
                      </InputLabel>
                      <SelectStato
                        {...field}
                        label='Comercial Responsável'
                      >
                      </SelectStato>
                    </FormControl>
                  )}
                />
              </GridItemCadastro>

              <GridItemCadastro item xs={12} sm={4} md={4}>
                <Controller
                  control={methods.control}
                  name='idConsultorMaster'
                  render={({ field }) => (
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel id='consultor-master-select-label'>
                        Consultor Responsável
                      </InputLabel>
                      <SelectStato {...field} label='Consultor Responsável'>
                        {consultorOnboardings &&
                          consultorOnboardings!.map(
                            (consultores: IConsultor, index: number) => (
                              <MenuItem value={consultores.id} key={index}>
                                {consultores.nome}
                              </MenuItem>
                            )
                          )}
                      </SelectStato>
                    </FormControl>
                  )}
                />
              </GridItemCadastro>

              <GridItemCadastro item xs={12} sm={12} md={12}>
                {comanda?.idConsultorOnboarding !== undefined && comanda?.idConsultorOnboarding !== 0 &&
                  <Onboarding comanda={comanda} consultorOnboardings={consultorOnboardings} />
                }
              </GridItemCadastro>

              <GridItemCadastro>
                <ButtonStato variant='contained' type='submit' disabled={loading}>
                  Salvar
                </ButtonStato>
              </GridItemCadastro>

              <GridItemCadastro
                item
                xs={12}
                sm={12}
                md={12}
                direction='column'
                sx={{ paddingTop: '0px !important' }}
              >
                <Divider />
              </GridItemCadastro>
              <Grid item xs={12} md={12} sx={{ marginBottom: '70px' }}>
                <ReuniaoComponent
                  etapas={comanda.etapas}
                  comandaEtapas={comanda.comandaEtapaReunioes}
                  idComanda={comanda.id}
                  buComanda={comanda.bUs}
                  modeloComercial={ModeloComercial[comanda.modeloComercial as keyof typeof ModeloComercial]}
                />
              </Grid>

              <GridItemCadastro item xs={12} sm={12} md={12} direction='column'>
                <Divider />
              </GridItemCadastro>

              <HistoricoComponent comandaId={comanda.id} contatoId={id} />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default ProgramaCrm;
