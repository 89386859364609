import { styled } from 'styled-components';
import Menu from '../../../../components/FerramentasAdministrativas/Menu';
import {
  Box,
  Grid,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Icon,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IConsultor } from '../../../../commons/genericTypes';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ButtonStato, GridContainer } from '../../../../commons/styleds';
import React from 'react';
import ConsultorStepper1 from './Steps/stepper1';
import ConsultorStepper2 from './Steps/stepper2';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { possuiPermissao } from '../../../../commons/validaPermissao';
import { useConsultorActions } from '../../../../states/config/CadastroUsuarios/consultores/consultor.actions';

const empty: IConsultor = {
  id: 0,
  nome: '',
  email: '',
  tipoConsultorB2C: false,
  tipoConsultorB2B: false,
  celular: '',
  ativo: true,
  senha: '',
  consultorTipos: [],
};

const steps = ['', ''];

const Consultor: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const consultorActions = useConsultorActions();
  const [novoConsultor, setNovoConsultor] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [consultores, setConsultores] = useState<IConsultor[]>([]);
  const [consultorTemp, setConsultorTemp] = useState<IConsultor>(empty);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (!possuiPermissao(auth, ['cadastro de usuarios'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    atualizarFormulario(empty);
    setIdAtualizar(0);
    consultorActions
      .list()
      .then((resp: any) => setConsultores(resp.resultado))
      .catch((err: any) => toast.warn(err));
    setLoading(false);
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 165,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 220,
      editable: true,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 140,
      editable: true,
    },
    {
      field: 'action',
      headerName: ' ',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IConsultor;
        return (
          <Icon
            onClick={() => handleClickOpen(obj)}
            style={{ color: 'gray', cursor: 'pointer' }}
          >
            delete
          </Icon>
        );
      },
    },
  ];

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setConsultorTemp(obj);
  };

  const deleteValue = () => {
    consultorActions
      .delete(consultorTemp.id)
      .then((resp: IConsultor[]) => {
        const sub = consultores.filter((f) => f.id !== consultorTemp.id);
        setConsultores(sub);
        atualizarFormulario(empty);
      })
      .catch((err: any) => toast.warn(err));

    methods.reset(consultorTemp, { keepDefaultValues: true });
    setIdAtualizar(consultorTemp.id);
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const podeEnviar = (data: IConsultor) => {
    if (activeStep < 2) return;
    if (!data.nome) {
      setActiveStep(0);
      toast.error('Nome é obrigatório!');
      return false;
    }
    if (!data.email) {
      setActiveStep(0);
      toast.error('Email é obrigatório!');
      return false;
    }
    if (data.senha !== data.confirmarSenha && idAtualizar === 0) {
      setActiveStep(0);
      toast.error('Senha e Confirma Senha estão diferentes !');
      return false;
    }
    return true;
  };

  const onSubmit = async (data: IConsultor) => {
    if (!podeEnviar(data)) return;

    if (data.id > 0) atualizarConsultor(data);
    else criarConsultor(data);
  };

  const atualizarConsultor = (data: any) => {
    consultorActions
      .update(idAtualizar ?? 0, data)
      .then((result: any) => {
        const sub = consultores.filter((f) => f.id !== result.resultado.id);
        setConsultores(sub.concat(result.resultado));
        atualizarFormulario(empty);
        setActiveStep(0);
        toast.success('Atualizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };

  const criarConsultor = (data: any) => {
    consultorActions
      .create(data)
      .then((result: IConsultor) => {
        setConsultores(consultores.concat(result));
        atualizarFormulario(empty);
        setActiveStep(0);
        toast.success('Cadastro realizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Cadastrar!');
      });
  };

  const cancelar = () => {
    setActiveStep(0);
    atualizarFormulario(empty);
  };

  const atualizarFormulario = (result: IConsultor) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setActiveStep(0);
    setNovoConsultor(result.id == 0);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return <ConsultorStepper1 novoConsultor={novoConsultor} />;
      case 1:
        return <ConsultorStepper2 />;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
    setConsultorTemp(empty);
  };

  const dialogExcluirConsultor = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {'Tem certeza que dejesa excluir esse consultor?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={deleteValue}>Sim</Button>
        <Button
          variant='contained'
          color='error'
          onClick={handleClose}
          autoFocus
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <BasePage>
      <Menu />

      {dialogExcluirConsultor}

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato
                id='formArea'
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <h3>
                  {idAtualizar
                    ? `Atualizar Consultor - ID: ${idAtualizar}`
                    : 'Novo Consultor'}
                </h3>

                <Stepper activeStep={activeStep} sx={{ marginTop: '5px' }}>
                  {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <React.Fragment>
                  {getStep()}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <ButtonStato
                      color='inherit'
                      variant='contained'
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Voltar
                    </ButtonStato>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {idAtualizar! > 0 ? (
                      <ButtonStato
                        color='error'
                        variant='contained'
                        sx={{ marginRight: '10px' }}
                        onClick={cancelar}
                      >
                        {'Cancelar'}
                      </ButtonStato>
                    ) : (
                      <></>
                    )}
                    {activeStep >= 1 ? (
                      <ButtonStato
                        type='submit'
                        onClick={handleNext}
                        variant='contained'
                      >
                        {'Salvar'}
                      </ButtonStato>
                    ) : (
                      <ButtonStato variant='contained' onClick={handleNext}>
                        {'Próximo'}
                      </ButtonStato>
                    )}
                  </Box>
                </React.Fragment>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={consultores ?? []}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

// TODO: está sendo usado em mais de um local, mandar para algum base.
export const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

export default Consultor;
