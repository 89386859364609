import { programaAtom } from './ProgramaState';
import { useBaseActions } from '../../../base.actions';

function useProgramaActions() {
  const baseActions = useBaseActions('programas', programaAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useProgramaActions };
