import { CircularProgress, FormControl, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { ISecao, ITbArea } from '../../../../commons/genericTypes';
import MultiSelectNome from '../../../../components/Generics/MultiSelectNome';
import { styled } from 'styled-components';
import { TextFieldStato } from '../../../../commons/styleds';
import EditorHtml from '../../../../components/EditorHtml/EditorHtml';

interface Props {
    novoConteudo: boolean;
    tbAreas: ITbArea[];
    tbSecoes: ISecao[];
}

const ConteudoStepper1: React.FC<Props> = (props: Props) => {
    const { novoConteudo, tbAreas, tbSecoes } = props;
    const methods = useFormContext();

    return (
        <>
            <>
                {
                    !tbAreas &&
                    <Grid item xs={12} md={12} sx={{ paddingTop: "30px", textAlign: "center", marginBottom: "-85px" }}>
                        <CircularProgress
                            style={{
                                width: '60px',
                                height: '60px',
                            }}
                        />
                    </Grid>
                }
            </>

            <Grid item xs={12} md={4} sx={{ paddingTop: "20px" }}></Grid>

            <Controller
                control={methods.control}
                name='areas'
                render={({ field: { ref, onChange, value, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        {tbAreas && (
                            <MultiSelectNome
                                opcoes={tbAreas as ITbArea[]}
                                setValor={onChange}
                                valor={value || []}
                                label='Menu'
                            />
                        )}
                    </FormControl>
                )}
            />

            <Grid item xs={12} md={4} sx={{ paddingTop: "20px" }}></Grid>

            <Controller
                control={methods.control}
                name='secaos'
                render={({ field: { ref, onChange, value, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        {tbSecoes && (
                            <MultiSelectNome
                                opcoes={tbSecoes as ISecao[]}
                                setValor={onChange}
                                valor={value || []}
                                label='SubMenus'
                            />
                        )}
                    </FormControl>
                )}
            />

            <Controller
                control={methods.control}
                name='titulo'
                render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        <FormInput
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            label='Titulo'
                        />
                    </FormControl>
                )}
            />

            <Controller
                control={methods.control}
                name='resumo'
                render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        <FormInput
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            label='Resumo'
                        />
                    </FormControl>
                )}
            />

            {/* <Controller
                control={methods.control}
                name='texto'
                render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        <FormInput
                            id="filled-multiline-static"
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            multiline
                            maxRows={4}
                            label='Texto'
                        />
                    </FormControl>
                )}
            /> */}
            <Controller
                control={methods.control}
                name='texto'
                render={({ field: { ref, ...field } }) => (
                    <div style={{ paddingTop: '20px' }}>
                        <EditorHtml setAsHtml={field.onChange} initialHtml={field.value} label={'Descrição'} />
                    </div>
                )}
            />
        </>
    );
};

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

export default ConteudoStepper1;
