import { useBaseActions } from '../../base.actions';
import { secaoAtom } from './SecaoState';

function useSecaoActions() {
  const baseActions = useBaseActions('Secoes', secaoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useSecaoActions };
