import { planosTemplateAtom } from './PlanosTemplateState';
import { useBaseActions } from '../../../base.actions';

function usePlanosTemplateActions() {
  const baseActions = useBaseActions('templates', planosTemplateAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { usePlanosTemplateActions };
