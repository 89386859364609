import { setorAtom } from './SetorState';
import { useBaseActions } from '../../../base.actions';

function useSetorActions() {
  const baseActions = useBaseActions('configSetores', setorAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useSetorActions };
